import React, { useCallback, useEffect, useMemo } from 'react';

import { Set } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { StickyHeader } from 'components/atoms/StickyHeader';
import { DiffItemFilter } from 'components/pageComponents/GmbLocationUpdates/DiffItemFilter';
import { GmbLocationUpdatesFooter } from 'components/pageComponents/GmbLocationUpdates/GmbLocationUpdatesFooter';
import { UpdateTable } from 'components/pageComponents/GmbLocationUpdates/UpdateTable';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { useDisplayType } from 'hooks/useDisplayType';
import { GmbAttributes, GmbUrlAttributes } from 'models/Domain/GmbLocation/GmbAttributes';
import { MoreHoursList } from 'models/Domain/GmbLocation/MoreHours';
import { GmbLocationDiff } from 'models/Domain/GmbLocationDiffs';
import { GmbActions } from 'modules/gmb/actions';
import { GmbLocationUpdatesActions } from 'modules/gmbLocationUpdates/actions';
import { GmbLocationUpdatesSelectors } from 'modules/gmbLocationUpdates/selectors';
import { DiffItemFilterType } from 'modules/gmbLocationUpdates/types';
import { State } from 'modules/reducers';

export const GmbLocationUpdates: React.FC = () => {
  const dispatch = useDispatch();
  const {
    initialize,
    updateStore,
    updateStoreAttributes,
    updateStoreMoreHours,
    bulkUpdateStores,
    setCheckedItems,
    resetItem,
    toggleFilter,
    setStoreFilter,
  } = useMemo(() => bindActionCreators(GmbLocationUpdatesActions, dispatch), [dispatch]);
  const { getGmbCategoryList } = useMemo(() => bindActionCreators(GmbActions, dispatch), [dispatch]);

  useEffect(() => {
    initialize();
    getGmbCategoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stores = useSelector((state: State) => state.store.stores, shallowEqual);
  const categories = useSelector((state: State) => state.gmb.categoryList, shallowEqual);
  const {
    initialized,
    locationUpdates,
    filteredCheckedItems: checkedItems,
    updatedItems,
    filters,
    filterItems,
    selectableItems,
    attributeMetadatas,
    moreHoursTypes,
    serviceTypes,
  }: ReturnType<typeof GmbLocationUpdatesSelectors.selectState> = useSelector(
    GmbLocationUpdatesSelectors.selectState,
    shallowEqual,
  );

  // 選択中の差分にSTORECASTを保持できない項目があるかどうか
  const hasStorecastDataCannotApply = checkedItems.some((checkedItem: GmbLocationDiff) => {
    // 緯度経度はAPI経由で反映できないため、STORECAST側のデータで上書き不可
    if (checkedItem.key === 'latlng') return true;
    // 住所が更新できていない店舗の住所は、STORECAST側のデータで上書き不可
    if (checkedItem.key === 'address' && !stores.findStore(checkedItem.store_id)?.location_state.canApplyAddressToGmb) {
      return true;
    }
    return false;
  });

  const changeCheckBox = (diff: GmbLocationDiff) => {
    if (checkedItems.has(diff)) {
      setCheckedItems(checkedItems.remove(diff));
    } else {
      setCheckedItems(checkedItems.add(diff));
    }
  };

  const changeAllCheckBox = () => {
    // 全ての項目の対象から更新済み項目を除く
    if (checkedItems.size !== selectableItems.size) {
      setCheckedItems(selectableItems);
    } else {
      setCheckedItems(Set());
    }
  };

  // １つの項目をGoogleからの提案のデータで更新する（承認する）
  const onAdaptationGmb = useCallback(
    (target: GmbLocationDiff) => {
      updateStore({ target, type: 'GOOGLE_UPDATED' });
    },
    [updateStore],
  );
  // １つの項目をSTORECASTのデータで更新する（Googleからの提案の情報を却下する）
  const onAdaptationStorecast = useCallback(
    (target: GmbLocationDiff) => {
      updateStore({ target, type: 'STORECAST' });
    },
    [updateStore],
  );
  // 属性を個別に選択した値で更新する
  const onAdaptationCustomAttributes = useCallback(
    (target: GmbLocationDiff, attributes: GmbAttributes, urlAttributes: GmbUrlAttributes) => {
      updateStoreAttributes({ target, attributes, urlAttributes });
    },
    [updateStoreAttributes],
  );
  // その他の営業時間を個別に選択した値で更新する
  const onAdaptationCustomMoreHours = useCallback(
    (target: GmbLocationDiff, moreHours: MoreHoursList) => {
      updateStoreMoreHours({ target, moreHours });
    },
    [updateStoreMoreHours],
  );

  // 選択した項目をGoogleからの提案のデータで更新する（承認する）
  const onBulkAdaptationGmb = useCallback(() => {
    bulkUpdateStores({ type: 'GOOGLE_UPDATED' });
  }, [bulkUpdateStores]);
  // 選択した項目をSTORECASTのデータで更新する（Googleからの提案の情報を却下する）
  const onBulkAdaptationStorecast = useCallback(() => {
    bulkUpdateStores({ type: 'STORECAST' });
  }, [bulkUpdateStores]);

  // 選択した項目の更新状態(見た目)をリセットする
  const onResetItem = useCallback(
    (target: GmbLocationDiff) => {
      resetItem({ target });
    },
    [resetItem],
  );

  const onToggleFilter = useCallback(
    (value: DiffItemFilterType) => {
      toggleFilter({ value });
    },
    [toggleFilter],
  );

  const onChangeStoreFilter = useCallback(
    (storeIds: Set<number>) => {
      setStoreFilter({ storeIds });
    },
    [setStoreFilter],
  );

  const displayType = useDisplayType();
  const title = displayType.isDesktop ? 'Googleビジネスプロフィールとの差分管理' : 'GBPとの差分管理';

  return (
    <StyledMainWrapper isVisibleFooter={checkedItems.size > 0}>
      <Helmet title={getPageTitle('GBPとの差分')} />
      <StickyHeader title={title} />
      {initialized && (
        <>
          <StyledDiffItemFilter
            values={filters}
            toggleFilter={onToggleFilter}
            onChangeStoreFilter={onChangeStoreFilter}
          />
          <StyledWideBody withFooter={checkedItems.size > 0}>
            <UpdateTable
              gmbLocationUpdates={locationUpdates}
              moreHoursTypes={moreHoursTypes}
              serviceTypes={serviceTypes}
              attributeMetadatas={attributeMetadatas}
              categories={categories}
              stores={stores}
              checkedItems={checkedItems}
              updatedItems={updatedItems}
              filterItems={filterItems}
              isCheckedAllItems={checkedItems.size === selectableItems.size}
              onAdaptationGmb={onAdaptationGmb}
              onAdaptationStorecast={onAdaptationStorecast}
              onAdaptationCustomAttributes={onAdaptationCustomAttributes}
              onAdaptationCustomMoreHours={onAdaptationCustomMoreHours}
              onChangeCheckBox={changeCheckBox}
              onChangeAllCheckBox={changeAllCheckBox}
              onResetItem={onResetItem}
            />
          </StyledWideBody>
        </>
      )}
      {checkedItems.size > 0 && (
        <GmbLocationUpdatesFooter
          selectedSize={checkedItems.size}
          hasStorecastDataCannotApply={hasStorecastDataCannotApply}
          onCancel={onBulkAdaptationStorecast}
          onConfirm={onBulkAdaptationGmb}
        />
      )}
    </StyledMainWrapper>
  );
};

const StyledWideBody = styled(WideBody)<{ withFooter: boolean }>`
  /* 高さが余ってヘッダーがずれないようにタイトルヘッダー,絞り込み部分の高さを引く */
  height: ${({ withFooter }) => (withFooter ? 'calc(100vh - 72px - 109px - 100px)' : 'calc(100vh - 72px - 109px)')};
  min-height: calc(100vh - 72px - 109px - 100px);
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;

  &&& {
    overflow: scroll;
    padding-top: 0;
  }
`;

const StyledDiffItemFilter = styled(DiffItemFilter)`
  padding: 16px;
  /* テーブルのチェックボックス、テーブルヘッダーとの上下関係を整える */
  z-index: 10;
`;

const StyledMainWrapper = styled(MainWrapper)<{ isVisibleFooter: boolean }>`
  padding-bottom: ${(props) => (props.isVisibleFooter ? '100px' : '0')};
  padding-left: 0;
  padding-right: 0;
  height: 100vh;
`;
