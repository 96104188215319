import React, { useMemo } from 'react';

import { AttributeList } from 'components/organisms/AttributeList';
import { UrlAttributeList } from 'components/pageComponents/StoreDetail/UrlAttributeList';
import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbAttributes, GmbUrlAttributes } from 'models/Domain/GmbLocation/GmbAttributes';
import { GmbCategories } from 'models/Domain/GmbLocation/GmbCategories';
import { GmbLocation } from 'models/Domain/GmbLocation/GmbLocation';

export type Props = {
  // 表示対象の属性ID
  filterAttributeIds?: string[] | undefined;
  gmbLocation: GmbLocation;
  gmbCategoryList: GmbCategories;
  attributeMetadatas: GmbAttributeMetadatas;
};

export const GmbLocationAttributeValue: React.FC<Props> = ({ filterAttributeIds, gmbLocation, attributeMetadatas }) => {
  const urlAttributes = useMemo(() => {
    if (filterAttributeIds === undefined) {
      return gmbLocation.urlAttributes;
    }
    const filteredUrlAttributes = gmbLocation.urlAttributes.list.filter((attribute) =>
      filterAttributeIds.includes(attribute.attributeId),
    );
    return new GmbUrlAttributes({ list: filteredUrlAttributes });
  }, [filterAttributeIds, gmbLocation.urlAttributes]);

  const attributes = useMemo(() => {
    if (filterAttributeIds === undefined) {
      return gmbLocation.attributes;
    }
    const filteredAttributes = gmbLocation.attributes.list.filter((attribute) =>
      filterAttributeIds.includes(attribute.attributeId),
    );
    return new GmbAttributes({ list: filteredAttributes });
  }, [filterAttributeIds, gmbLocation.attributes]);

  return (
    <div>
      {!urlAttributes.list.isEmpty() && (
        <UrlAttributeList
          attributes={urlAttributes}
          attributeMetadatas={attributeMetadatas}
          displayEmptyAttributeGroup={false}
        />
      )}
      {
        // コピペした時にグループごとに改行が入るようにするための <br />
        !urlAttributes.list.isEmpty() && !attributes.list.isEmpty() && <br />
      }
      {!attributes.list.isEmpty() && (
        <AttributeList
          attributes={attributes}
          attributeMetadatas={attributeMetadatas}
          displayEmptyAttributeGroup={false}
        />
      )}
    </div>
  );
};
