import { List, Set } from 'immutable';
import actionCreatorFactory from 'typescript-fsa';

import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbAttributes, GmbUrlAttributes } from 'models/Domain/GmbLocation/GmbAttributes';
import { MoreHoursList, MoreHoursType } from 'models/Domain/GmbLocation/MoreHours';
import { ServiceType } from 'models/Domain/GmbLocation/Service';
import { GmbLocationDiff } from 'models/Domain/GmbLocationDiffs';
import { GmbLocationUpdates } from 'models/Domain/GmbLocationUpdates';

import { CheckedItemsType } from './reducers';
import { DiffItemFilterType, SimpleUpdateType, UpdateType } from './types';

const actionCreator = actionCreatorFactory('GmbLocationUpdates');

export const GmbLocationUpdatesActions = {
  initialize: actionCreator('initialize'),
  setInitialized: actionCreator('setInitialized'),
  fetchGmbLocationUpdates: actionCreator('fetchGmbLocationUpdates'),
  setGmbLocationUpdates: actionCreator<GmbLocationUpdates>('setGmbLocationUpdates'),
  setCheckedItems: actionCreator<CheckedItemsType>('setCheckedItems'),
  updateStore: actionCreator<{ target: GmbLocationDiff; type: SimpleUpdateType }>('updateStore'),
  updateStoreAttributes: actionCreator<{
    target: GmbLocationDiff;
    attributes: GmbAttributes;
    urlAttributes: GmbUrlAttributes;
  }>('updateStoreAttributes'),
  updateStoreMoreHours: actionCreator<{ target: GmbLocationDiff; moreHours: MoreHoursList }>('updateStoreMoreHours'),
  bulkUpdateStores: actionCreator<{ type: SimpleUpdateType }>('bulkUpdateStores'),
  setUpdatedItems: actionCreator<{ diffs: List<GmbLocationDiff>; type: UpdateType }>('setUpdated'),
  resetItem: actionCreator<{ target: GmbLocationDiff }>('resetItem'),
  toggleFilter: actionCreator<{ value: DiffItemFilterType }>('toggleFilter'),
  setStoreFilter: actionCreator<{ storeIds: Set<number> }>('setStoreFilter'),
  fetchAttributeMetadatas: actionCreator<GmbLocationUpdates>('fetchAttributeMetadatas'),
  setAttributeMetadatas: actionCreator<{ attributeMetadatas: { [categoryId: string]: GmbAttributeMetadatas } }>(
    'setAttributeMetadatas',
  ),
  fetchCategoryDatas: actionCreator<GmbLocationUpdates>('fetchCategoryDatas'),
  setCategoryDatas: actionCreator<{
    moreHoursTypes: { [categoryId: string]: List<MoreHoursType> };
    serviceTypes: { [categoryId: string]: List<ServiceType> };
  }>('setCategoryDatas'),
};
