import React from 'react';

import { List } from 'immutable';
import { Icon, Modal, Radio } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Counter } from 'components/atoms/Counter';
import { Input } from 'components/atoms/Input';
import { PullDown } from 'components/atoms/PullDown';
import { TextArea } from 'components/atoms/TextArea';
import { AttributesForm } from 'components/molecules/GmbAttributes/AttributesForm';
import { AttributesUrlForm } from 'components/molecules/GmbAttributes/AttributesUrlForm';
import { EditMoreHours } from 'components/organisms/EditMoreHours';
import { EditRegularHours } from 'components/organisms/EditRegularHours';
import { EditGmbCategoryList } from 'components/pageComponents/StoreDetail/EditGmbCategoryList';
import { EditSpecialHours } from 'components/pageComponents/StoreDetail/EditSpecialHours';
import { administrativeAreaOptions } from 'helpers/utils';
import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbCategories } from 'models/Domain/GmbLocation/GmbCategories';
import { openInfoOptions } from 'models/Domain/GmbLocation/GmbOpenInfo';
import { MAX_LENGTH as PROFILE_DESCRIPTION_MAX_LENGTH } from 'models/Domain/GmbLocation/GmbProfile';
import { GmbRegularHours } from 'models/Domain/GmbLocation/GmbRegularHours';
import { Store } from 'models/Domain/Store';
import { COLOR } from 'style/color';

import { EditOpeningDate } from './EditOpeningDate';

export type EditStoreItemModalType =
  | 'code'
  | 'name'
  | 'phone'
  | 'websiteUrl'
  | 'regularHours'
  | 'specialHours'
  | 'category'
  | 'address'
  | 'openInfo'
  | 'openingDate'
  | 'profile'
  | 'attributes'
  | 'urlAttributes'
  | 'moreHours';

export const EditStoreItemModal = React.memo<{
  isOpen: boolean;
  type: EditStoreItemModalType;
  store: Store;
  storeForEdit: Store;
  categoryList: GmbCategories;
  attributeMetadatas: GmbAttributeMetadatas;
  onClose: () => void;
  changeStore: (v: Store) => void;
  updateStore: () => void;
}>(({ isOpen, type, store, storeForEdit, onClose, changeStore, updateStore, categoryList, attributeMetadatas }) => {
  const validateResult = storeForEdit.validate();
  const { location } = storeForEdit;

  let isValid = false;
  switch (type) {
    case 'openInfo':
      isValid = validateResult.code.isValid;
      break;
    case 'openingDate':
      isValid = validateResult.openingDate.isValid;
      break;
    case 'code':
      isValid = validateResult.code.isValid;
      break;
    case 'name':
      isValid = validateResult.name.isValid && validateResult.branch.isValid;
      break;
    case 'phone':
      isValid =
        validateResult.primaryPhone.isValid &&
        validateResult.additionalPhones.filter((validate) => !validate.isValid).length === 0;
      break;
    case 'websiteUrl':
      isValid = validateResult.websiteUrl.isValid;
      break;
    case 'regularHours':
      isValid = validateResult.businessHours.isValid;
      break;
    case 'specialHours':
      isValid = validateResult.specialHours.isValid;
      break;
    case 'address':
      isValid =
        validateResult.postalCode.isValid &&
        validateResult.administrativeArea.isValid &&
        validateResult.address1.isValid &&
        validateResult.address2.isValid;
      break;
    case 'category':
      isValid = validateResult.primaryCategory.isValid && validateResult.additionalCategories.isValid;
      break;
    case 'profile':
      isValid = validateResult.profile.isValid;
      break;
    case 'attributes':
      isValid = validateResult.attributes.isValid;
      break;
    case 'urlAttributes':
      isValid = validateResult.urlAttributes.isValid;
      break;
    case 'moreHours':
      isValid = validateResult.moreHours.isValid;
      break;
    default:
      break;
  }

  return (
    <Modal size='small' open={isOpen} onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Content>
        {type === 'openInfo' && (
          <ItemWrapper>
            <ItemLabel>ステータス</ItemLabel>
            <EditContentWrapper>
              <RadioItemWrapper>
                <Radio
                  value={openInfoOptions.OPEN.value}
                  name='openInfo'
                  label={openInfoOptions.OPEN.name}
                  checked={storeForEdit.location.openInfo.status === openInfoOptions.OPEN.value}
                  onChange={(e) => changeStore(storeForEdit.changeOpenInfoStatus(openInfoOptions.OPEN.value))}
                />
              </RadioItemWrapper>
              <RadioItemWrapper>
                <Radio
                  value={openInfoOptions.CLOSED_PERMANENTLY.value}
                  name='openInfo'
                  label={openInfoOptions.CLOSED_PERMANENTLY.name}
                  checked={storeForEdit.location.openInfo.status === openInfoOptions.CLOSED_PERMANENTLY.value}
                  onChange={(e) =>
                    changeStore(storeForEdit.changeOpenInfoStatus(openInfoOptions.CLOSED_PERMANENTLY.value))
                  }
                />
              </RadioItemWrapper>
              <RadioItemWrapper>
                <Radio
                  value={openInfoOptions.CLOSED_TEMPORARILY.value}
                  name='openInfo'
                  label={openInfoOptions.CLOSED_TEMPORARILY.name}
                  checked={storeForEdit.location.openInfo.status === openInfoOptions.CLOSED_TEMPORARILY.value}
                  onChange={(e) =>
                    changeStore(storeForEdit.changeOpenInfoStatus(openInfoOptions.CLOSED_TEMPORARILY.value))
                  }
                />
              </RadioItemWrapper>
            </EditContentWrapper>
          </ItemWrapper>
        )}
        {type === 'openingDate' && <EditOpeningDate changeStore={changeStore} storeForEdit={storeForEdit} />}
        {type === 'code' && (
          <ItemWrapper>
            <ItemLabel>店舗コード</ItemLabel>
            <EditContentWrapper>
              <Input
                value={storeForEdit.code}
                error={!validateResult.code.isValid && validateResult.code.error}
                onChange={(value) => changeStore(storeForEdit.changeCode(value))}
              />
            </EditContentWrapper>
          </ItemWrapper>
        )}
        {type === 'name' && (
          <ItemWrapper>
            <ItemLabel>店名</ItemLabel>
            <EditContentWrapper>
              <Input
                value={storeForEdit.name}
                error={!validateResult.name.isValid && validateResult.name.error}
                onChange={(value: string) => changeStore(storeForEdit.changeName(value))}
              />
            </EditContentWrapper>

            <ItemLabel>支店名</ItemLabel>
            <EditContentWrapper>
              <Input
                value={storeForEdit.branch}
                error={!validateResult.branch.isValid && validateResult.branch.error}
                onChange={(value: string) => changeStore(storeForEdit.changeBranchName(value))}
              />
            </EditContentWrapper>
          </ItemWrapper>
        )}
        {type === 'phone' && (
          <ItemWrapper>
            <ItemLabel>電話番号</ItemLabel>
            <EditContentWrapper>
              <Input
                value={location.primaryPhone}
                error={!validateResult.primaryPhone.isValid && validateResult.primaryPhone.error}
                onChange={(value) => changeStore(storeForEdit.changePrimaryPhone(value))}
                placeholder='電話番号'
              />
            </EditContentWrapper>
            {location.primaryPhone &&
              location.additionalPhonesForEdit.map((additionalPhone, index) => {
                return (
                  <EditContentWrapper key={index}>
                    <Input
                      value={additionalPhone}
                      error={
                        !validateResult.additionalPhones[index].isValid && validateResult.additionalPhones[index].error
                      }
                      onChange={(value) => changeStore(storeForEdit.changeAdditionalPhones(index, value))}
                      placeholder='追加の電話番号'
                    />
                  </EditContentWrapper>
                );
              })}
          </ItemWrapper>
        )}
        {type === 'websiteUrl' && (
          <ItemWrapper>
            <ItemLabel>ウェブサイト</ItemLabel>
            <EditContentWrapper>
              <Input
                value={location.websiteUrl}
                error={!validateResult.websiteUrl.isValid && validateResult.websiteUrl.error}
                onChange={(value) => changeStore(storeForEdit.changeWebsiteUrl(value))}
                placeholder={'例 https://storecast.io'}
              />
            </EditContentWrapper>
          </ItemWrapper>
        )}
        {type === 'address' && (
          <ItemWrapper>
            <ItemLabel>所在地</ItemLabel>
            <EditContentWrapper>
              <FlexWrapper>
                <LeftContent>
                  <ItemLabel>郵便番号</ItemLabel>
                  <EditContentWrapper>
                    <Input
                      value={location.address.postalCode}
                      error={!validateResult.postalCode.isValid && validateResult.postalCode.error}
                      onChange={(value) => changeStore(storeForEdit.changePostalCode(value))}
                    />
                  </EditContentWrapper>
                </LeftContent>
                <RightContent>
                  <ItemLabel>都道府県</ItemLabel>
                  <EditContentWrapper>
                    <SmallPullDown
                      value={location.address.administrativeArea}
                      options={administrativeAreaOptions}
                      placeholder={'都道府県'}
                      error={!validateResult.administrativeArea.isValid && validateResult.administrativeArea.error}
                      onChange={(value) => changeStore(storeForEdit.changeAdministrativeArea(value))}
                    />
                  </EditContentWrapper>
                </RightContent>
              </FlexWrapper>
              <ItemLabel>住所1</ItemLabel>
              <EditContentWrapper>
                <Input
                  value={location.address.address1}
                  error={!validateResult.address1.isValid && validateResult.address1.error}
                  onChange={(value) => changeStore(storeForEdit.changeAddress1(value))}
                />
              </EditContentWrapper>
              <ItemLabel>住所2</ItemLabel>
              <EditContentWrapper>
                <Input
                  value={location.address.address2}
                  error={!validateResult.address2.isValid && validateResult.address2.error}
                  onChange={(value) => changeStore(storeForEdit.changeAddress2(value))}
                />
              </EditContentWrapper>
            </EditContentWrapper>
            <EditContentWrapper>
              <Icon name='info circle' /> 住所を変更後、緯度経度の反映に1日程度時間がかかる場合があります。
            </EditContentWrapper>
          </ItemWrapper>
        )}
        {type === 'regularHours' && (
          <EditRegularHours
            regularHours={storeForEdit.location.regularHours}
            changeRegularHours={(value: GmbRegularHours) => {
              changeStore(storeForEdit.changeRegularHours(value));
            }}
          />
        )}
        {type === 'moreHours' && (
          <EditMoreHours
            moreHours={storeForEdit.location.moreHours}
            moreHoursTypes={storeForEdit.location.availableMoreHoursTypes}
            changeMoreHours={(value) => {
              changeStore(storeForEdit.changeMoreHours(value));
            }}
          />
        )}
        {type === 'specialHours' && <EditSpecialHours changeStore={changeStore} storeForEdit={storeForEdit} />}
        {type === 'category' && (
          <EditGmbCategoryList categoryList={categoryList} changeStore={changeStore} storeForEdit={storeForEdit} />
        )}
        {type === 'attributes' && (
          <ItemWrapper>
            <ItemLabel>属性</ItemLabel>
            <EditContentWrapper>
              <AttributesForm
                attributeMetadatas={attributeMetadatas}
                attributes={storeForEdit.location.attributes}
                individualUpdate={false}
                setAttributes={(attributes) => changeStore(storeForEdit.changeAttributes(attributes))}
                targetAttributeIds={List()}
                setTargetAttributeIds={() => {
                  /* 何もしない */
                }}
              />
            </EditContentWrapper>
          </ItemWrapper>
        )}
        {type === 'urlAttributes' && (
          <ItemWrapper>
            <EditContentWrapper>
              <AttributesUrlForm
                attributeMetadatas={attributeMetadatas}
                attributes={storeForEdit.location.urlAttributes}
                setAttributes={(attributes) => changeStore(storeForEdit.changeUrlAttributes(attributes))}
              />
            </EditContentWrapper>
          </ItemWrapper>
        )}
        {type === 'profile' && (
          <ItemWrapper>
            <ItemLabel>ビジネス情報</ItemLabel>
            <EditContentWrapper>
              <StyledTextArea
                value={location.profile.description}
                onChange={(value) => changeStore(storeForEdit.changeProfileDescription(value))}
                placeholder='ビジネス情報'
                error={!validateResult.profile.isValid ? validateResult.profile.error : false}
              />
              <StyledTextCount size={location.profile.description.length} maxSize={PROFILE_DESCRIPTION_MAX_LENGTH} />
            </EditContentWrapper>
          </ItemWrapper>
        )}
      </Modal.Content>
      <ButtonWrapper>
        <CloseButton onClick={() => onClose()}>キャンセル</CloseButton>
        <ApplyButton priority='high' onClick={() => updateStore()} disabled={!isValid}>
          適用
        </ApplyButton>
      </ButtonWrapper>
    </Modal>
  );
});

const SmallPullDown = styled(PullDown)`
  &&& {
    & > .ui.selection.dropdown {
      padding: 17px 16px;
      min-height: 53px;
      & > input {
        padding: 17px 16px !important;
      }
    }
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const LeftContent = styled.div`
  padding-right: 16px;
`;

const RightContent = styled.div``;

const ItemWrapper = styled.div``;

const RadioItemWrapper = styled.div`
  margin-top: 8px;
`;

const ItemLabel = styled.label`
  color: ${COLOR.BLACK};
  display: block;
  margin-top: 8px;
  font-weight: bold;
  &:first-of-type {
    margin-top: 0;
  }
`;

const EditContentWrapper = styled.div`
  margin-top: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 14px;
`;

const ButtonCommonStyle = css`
  max-width: 150px;
  width: calc(50% - 10px);
  padding: 17px 8px;
`;

const CloseButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
  }
`;

const ApplyButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle};
    margin-left: 20px;
  }
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    width: 100%;
  }
`;

const StyledTextCount = styled(Counter)`
  margin-top: 4px;
  text-align: right;
`;
