import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

import { GmbLocation } from './GmbLocation/GmbLocation';
import { GmbLocationDiff, GmbLocationDiffs } from './GmbLocationDiffs';

interface GmbLocationUpdateRecord {
  store_id: number;
  location: GmbLocation;
  location_updated: GmbLocation;
  diffs: List<GmbLocationDiff>;
}

export class GmbLocationUpdate extends Record<GmbLocationUpdateRecord>({
  store_id: 0,
  location: new GmbLocation(),
  location_updated: new GmbLocation(),
  diffs: List(),
}) {
  static fromJSON(data: JSObject = {}) {
    const params = { ...data };
    params.location = new GmbLocation(params.location);
    params.location_updated = new GmbLocation(params.location_updated);
    params.diffs = GmbLocationDiffs.fromJSON(params.diffs).list;
    return new GmbLocationUpdate(params);
  }

  getLocation(type: 'STORECAST' | 'GOOGLE_UPDATED') {
    return type === 'STORECAST' ? this.location : this.location_updated;
  }

  /**
   * 属性情報の差分が出ているid以外削除したものを返す
   */
  filterAttributeDiffs() {
    return this.set('location', this.location.filterAttributeDiffs(this.location_updated)).set(
      'location_updated',
      this.location_updated.filterAttributeDiffs(this.location),
    );
  }

  /**
   * ロケーション（STORECAST/GBP)のプライマリーカテゴリーを返す
   * @returns
   */
  getPrimaryCategoryIds() {
    return List([this.location.primaryCategory.categoryId, this.location_updated.primaryCategory.categoryId])
      .filter((categoryId) => !!categoryId)
      .filter((target, idx, self) => {
        return self.indexOf(target) === idx;
      });
  }

  /**
   * ロケーション（STORECAST/GBP)のカテゴリーを返す(additionalCategoryを含む)
   * @returns
   */
  getAllCategoryIds() {
    return List([this.location.primaryCategory.categoryId, this.location_updated.primaryCategory.categoryId])
      .concat(this.location.additionalCategories.list.map((category) => category.categoryId))
      .concat(this.location_updated.additionalCategories.list.map((category) => category.categoryId))
      .filter((categoryId) => !!categoryId)
      .filter((target, idx, self) => {
        return self.indexOf(target) === idx;
      });
  }
}

interface GmbLocationUpdatesRecord {
  list: List<GmbLocationUpdate>;
}

export class GmbLocationUpdates extends Record<GmbLocationUpdatesRecord>({
  list: List(),
}) {
  static fromJSON(data: JSObject[] = []) {
    const list = List(data.map((p) => GmbLocationUpdate.fromJSON(p)));
    return new GmbLocationUpdates({ list });
  }

  getLocation(storeId: number, type: 'STORECAST' | 'GOOGLE_UPDATED') {
    return this.list.find((v) => v.store_id === storeId)?.getLocation(type);
  }

  get allStoresDiffs(): List<GmbLocationDiff> {
    return this.list
      .map((lu) => lu.diffs)
      .flatten()
      .toList();
  }

  get primaryCategoryIds() {
    // 各差分のロケーションのSTORECAST/GBPのメインカテゴリIDを取得
    const categoryIds: List<string> = this.list.reduce(
      (acc, target) => acc.concat(target.getPrimaryCategoryIds()),
      List(),
    );

    return categoryIds
      .filter((categoryId) => !!categoryId)
      .filter((target, idx, self) => {
        return self.indexOf(target) === idx;
      })
      .toArray();
  }

  get allCategoryIds() {
    // 各差分のロケーションのSTORECAST/GBPのメインカテゴリIDを取得
    const categoryIds: List<string> = this.list.reduce((acc, target) => acc.concat(target.getAllCategoryIds()), List());

    return categoryIds
      .filter((categoryId) => !!categoryId)
      .filter((target, idx, self) => {
        return self.indexOf(target) === idx;
      })
      .toArray();
  }

  isEmpty() {
    return this.list.size === 0;
  }
}
