import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import {
  CallToAction as CallToActionParams,
  PromotionPostParams,
  PromotionSummaryDetailResponse,
  TimeIntervalDate as TimeIntervalDateParams,
  TimeInterval as TimeIntervalParams,
  TimeIntervalTime as TimeIntervalTimeParams,
  TranslationPostData as TranslationPostDataParams,
  Translations as TranslationsParams,
} from 'ApiClient/PromotionApi';
import CONFIG from 'config';
import { extractContainWords, extractPhoneNumber, validateWebsiteUrl } from 'helpers/utils';
import { JSObject } from 'types/Common';

import { TranslationLanguage } from '../Organization';
import { Store, Stores } from '../Store';

type PromotionParams = PromotionPostParams['promotions'];

export const PROMOTION_TOPIC_TYPE_KEYS = ['STANDARD', 'ALERT', 'OFFER', 'EVENT'] as const;
export type PromotionTopicType = (typeof PROMOTION_TOPIC_TYPE_KEYS)[number];
export const PROMOTION_TOPIC_TYPE_KEYS_WITH_ALL = [...PROMOTION_TOPIC_TYPE_KEYS, 'ALL'] as const;
export type PromotionTopicTypeWithALL = (typeof PROMOTION_TOPIC_TYPE_KEYS_WITH_ALL)[number];
export const ACTION_TYPES_WITH_URL = ['BOOK', 'ORDER', 'SHOP', 'LEARN_MORE', 'SIGN_UP'];
const VALID_IMAGE_FILE_TYPES = ['image/jpeg', 'image/png'];

// 画像を利用可能なトピックタイプ
export const TOPIC_TYPES_WITH_IMAGE: PromotionTopicType[] = ['STANDARD', 'OFFER', 'EVENT'];
// CALL_TO_ACTIONを利用可能なトピックタイプ
export const TOPIC_TYPES_WITH_CALL_TO_ACTION: PromotionTopicType[] = ['STANDARD', 'ALERT', 'EVENT'];
// イベント情報を利用可能なトピックタイプ
export const TOPIC_TYPES_WITH_EVENT: PromotionTopicType[] = ['OFFER', 'EVENT'];
// 特典情報を利用可能なトピックタイプ
export const TOPIC_TYPES_WITH_OFFER: PromotionTopicType[] = ['OFFER'];

// STANDARDなら最新情報、ALERTならCOVID-19の最新情報、OFFERなら特典、EVENTならイベントとして処理
// 他に取り扱いたいトピックがあればそれを、
// COVID-19の最新情報ではないALERTを追加したい場合は判別用のフラグを追加する必要がある

export const PromotionTopicTypeLabel = {
  STANDARD: '最新情報',
  ALERT: 'COVID-19の最新情報',
  OFFER: '特典',
  EVENT: 'イベント',
  ALL: 'すべて',
};

export type PromotionActionTypeKey =
  | 'ACTION_TYPE_UNSPECIFIED'
  | 'BOOK'
  | 'ORDER'
  | 'SHOP'
  | 'LEARN_MORE'
  | 'SIGN_UP'
  | 'CALL';

/** 投稿のステータス */
export type PromotionStateType = 'SCHEDULED' | 'QUEUED' | 'INPROGRESS' | 'POSTED' | 'FAILED';

/** GBP上の投稿のステータス */
export type PromotionGmbStateType =
  | 'LOCAL_POST_STATE_UNSPECIFIED' // 投稿中 (RPA投稿実行前 or RPA投稿実行中 or RPA投稿失敗or 要復旧 or RPA投稿成功 or GBP上のステータス不明)
  | 'PROCESSING' //投稿中 (RPA投稿成功、GBPで審査中)
  | 'LIVE' //公開済み
  | 'REJECTED'; //公開拒否

export const PromotionActionType = {
  ACTION_TYPE_UNSPECIFIED: 'なし',
  BOOK: '予約',
  ORDER: 'オンライン注文',
  SHOP: '購入',
  LEARN_MORE: '詳細',
  SIGN_UP: '登録',
  CALL: '今すぐ電話',
};

export type AliasType = '{{店名}}' | '{{支店名}}' | '{{店舗コード}}' | '{{予約リンク}}' | '{{ウェブサイト}}';

export const AvailableAliases: { [key in 'body' | 'url']: AliasType[] } = {
  body: ['{{店名}}', '{{支店名}}', '{{予約リンク}}', '{{ウェブサイト}}'],
  url: ['{{店舗コード}}', '{{予約リンク}}', '{{ウェブサイト}}'],
};

export const AliasLinkType = ['{{予約リンク}}', '{{ウェブサイト}}'];

export const TITLE_MAX_LENGTH = 58;
export const BODY_MAX_LENGTH = 1500;
export const COUPON_CODE_MAX_LENGTH = 58;
export const TERMS_CONDITIONS_MAX_LENGTH = 5000;

type MediaFormat = 'MEDIA_FORMAT_UNSPECIFIED' | 'PHOTO' | 'VIDEO';

export const PROMOTION_LANGUAGE_LABELS = {
  '': '日本語',
  en: '英語',
  'zh-CN': '中国語（簡体字）',
  ko: '韓国語',
};

/**
 * 文字列から指定された文字列を削除する
 */
export const removeAll = (text: string, targets: string[]) => {
  targets.forEach((target) => {
    const re = new RegExp(target, 'g');
    text = text.replace(re, '');
  });

  return text;
};

export interface SummaryRecord {
  unspecified_post_count: number;
  processing_post_count: number;
  live_post_count: number;
  reject_post_count: number;
}

export interface PostStatus {
  local_post_doc_id: string;
  store_id: number;
  local_post_name: string;
  state: PromotionStateType;
  gmb_state: PromotionGmbStateType;
  post_create_at: string | null;
  post_update_at: string | null;
}

export interface PromotionStoreRecord {
  store_id: number;
  state: PromotionStateType;
  gmb_state: PromotionGmbStateType;
}
export class PromotionStore extends ImmutableRecord<PromotionStoreRecord>({
  store_id: 0,
  state: 'QUEUED',
  gmb_state: 'LOCAL_POST_STATE_UNSPECIFIED',
}) {}

export type TranslationPostDataType = {
  id: string | null;
  language: TranslationLanguage;
  title: string | null;
  body: string;
  call_to_action: CallToAction | null;
};

export class TranslationPostData extends ImmutableRecord<TranslationPostDataType>({
  id: null,
  language: 'en',
  title: null,
  body: '',
  call_to_action: null,
}) {
  static fromJSON(data: TranslationPostDataParams) {
    return new TranslationPostData({
      id: data._id ?? null,
      language: data.language as TranslationLanguage,
      title: data.title ?? null,
      body: data.body ?? '',
      call_to_action: data.call_to_action ? new CallToAction(data.call_to_action) : null,
    });
  }

  updateParams(): TranslationPostDataParams {
    return {
      _id: this.id ?? undefined,
      language: this.language,
      title: this.title ?? undefined,
      body: this.body ?? '',
      call_to_action: this.call_to_action ? this.call_to_action.requestParams() : undefined,
    };
  }
}

export class Translations extends ImmutableRecord<{
  parent: string | null;
  language: TranslationLanguage | null;
  children: ImmutableList<TranslationPostData> | null;
}>({
  parent: null,
  language: null,
  children: null,
}) {
  static fromJSON(data: TranslationsParams) {
    return new Translations({
      parent: data.parent ?? null,
      language: data.language ?? null,
      children: data.children ? ImmutableList(data.children.map((item) => TranslationPostData.fromJSON(item))) : null,
    });
  }

  /**
   * 子投稿に使われる言語一覧を取得する
   */
  get childrenLanguages() {
    return this.children?.map((child) => child.language) ?? ImmutableList();
  }
}

export interface CallToActionRecord {
  action_type: PromotionActionTypeKey;
  url: string;
}
export class CallToAction extends ImmutableRecord<CallToActionRecord>({
  action_type: 'ACTION_TYPE_UNSPECIFIED',
  url: '',
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    // URLが存在しないaction_typeの場合も空文字を設定しておく
    if (!params.url) {
      params.url = '';
    }
    super(params);
  }

  get actionTypeLabel() {
    return PromotionActionType[this.action_type];
  }

  get isSpecified() {
    return this.action_type !== 'ACTION_TYPE_UNSPECIFIED';
  }

  /** URLの入力が可能なボタンタイプ */
  get canInputUrl(): boolean {
    return ACTION_TYPES_WITH_URL.includes(this.action_type);
  }

  /** エイリアスを除いたURL */
  get urlWithoutAlias() {
    return removeAll(this.url, AvailableAliases.url);
  }

  changeValue<K extends keyof CallToActionRecord>(key: K, value: CallToActionRecord[K]) {
    return this.set(key, value);
  }

  requestParams() {
    const params: CallToActionParams = { action_type: this.action_type, url: null };
    if (ACTION_TYPES_WITH_URL.includes(this.action_type)) {
      params.url = this.url;
    }
    return params;
  }
}

export interface OfferRecord {
  couponCode: string;
  redeemOnlineUrl: string;
  termsConditions: string;
}

export class Offer extends ImmutableRecord<OfferRecord>({
  couponCode: '',
  redeemOnlineUrl: '',
  termsConditions: '',
}) {
  static fromJSON(data: JSObject = {}): Offer {
    const params = { ...data };
    return new Offer({
      couponCode: params.coupon_code ?? '',
      redeemOnlineUrl: params.redeem_online_url ?? '',
      termsConditions: params.terms_conditions ?? '',
    });
  }

  requestParams(): PromotionParams['offer'] {
    return {
      coupon_code: this.couponCode,
      redeem_online_url: this.redeemOnlineUrl,
      terms_conditions: this.termsConditions,
    };
  }

  validate() {
    return [
      { type: 'couponCode', error: this.validateCouponCode() },
      { type: 'termsConditions', error: this.validateTermsConditions() },
    ];
  }

  get isValid() {
    const validates = this.validate();
    return (
      validates.filter((target) => {
        return target.error.name;
      }).length === 0
    );
  }

  validateCouponCode() {
    const errors: JSObject = {};
    if (this.couponCode.length > COUPON_CODE_MAX_LENGTH) {
      errors.name = `「クーポンコード」は${COUPON_CODE_MAX_LENGTH}文字以内です`;
    }
    return errors;
  }

  validateTermsConditions() {
    const errors: JSObject = {};
    if (this.termsConditions.length > TERMS_CONDITIONS_MAX_LENGTH) {
      errors.name = `「利用規約」は${TERMS_CONDITIONS_MAX_LENGTH}文字以内です`;
    }
    return errors;
  }
}

type DateYMDRecord = {
  year: number;
  month: number;
  day: number;
};

// 年月日により日付を扱うクラス。タイムゾーンはJSTとして扱う
export class DateYMD extends ImmutableRecord<DateYMDRecord>({
  year: 2000,
  month: 1,
  day: 1,
}) {
  static fromJSON(data: JSObject = {}): DateYMD | null {
    const params = { ...data };
    const { year, month, day } = params;
    if (year == null || month == null || day == null) {
      return null;
    }
    return new DateYMD(params);
  }

  static fromDate(date: Date | null): DateYMD | null {
    if (date == null) {
      return null;
    }
    return new DateYMD({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    });
  }

  /** 「YYYY/MM/DD」形式の文字列として返す */
  toText(): string {
    return `${this.year}/${('00' + this.month).slice(-2)}/${('00' + this.day).slice(-2)}`;
  }

  /** Dateとして返す */
  toDate(): Date {
    // 日付しか使わないので0時(JST)として返す
    const dateText = this.toText();
    const timeText = '00:00:00';
    return dayjs(`${dateText} ${timeText}`).toDate();
  }

  requestParams(): TimeIntervalDateParams {
    return {
      year: this.year,
      month: this.month,
      day: this.day,
    };
  }
}

type TimeOfDayRecord = {
  hours: number;
  minutes: number;
  seconds: number;
};

// 時分秒により時間を扱うクラス。タイムゾーンはJSTとして扱う
export class TimeOfDay extends ImmutableRecord<TimeOfDayRecord>({
  hours: 0,
  minutes: 0,
  seconds: 0,
}) {
  static fromJSON(data: JSObject = {}): TimeOfDay | null {
    const params = { ...data };
    const { hours, minutes, seconds } = params;
    if (hours == null) {
      return null;
    }
    return new TimeOfDay({
      hours: hours,
      minutes: minutes ?? 0,
      seconds: seconds ?? 0,
    });
  }

  static fromDate(date: Date | null): TimeOfDay | null {
    if (date == null) {
      return null;
    }
    return new TimeOfDay({
      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds(),
    });
  }

  /** 「HH:mm:ss」形式の文字列として返す */
  toText() {
    return `${('00' + this.hours).slice(-2)}:${('00' + this.minutes).slice(-2)}:${('00' + this.seconds).slice(-2)}`;
  }

  /** Dateとして返す */
  toDate() {
    // 時間のデータしかないので、日付は今日にする
    const dateText = dayjs().format('YYYY/MM/DD');
    const timeText = this.toText();
    return dayjs(`${dateText} ${timeText}`).toDate();
  }

  requestParams(): TimeIntervalTimeParams {
    return {
      hours: this.hours,
      minutes: this.minutes,
      seconds: this.seconds,
    };
  }
}

export interface TimeIntervalRecord {
  startDate: DateYMD | null;
  startTime: TimeOfDay | null;
  endDate: DateYMD | null;
  endTime: TimeOfDay | null;
}

export class TimeInterval extends ImmutableRecord<TimeIntervalRecord>({
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
}) {
  static fromJSON(data: JSObject = {}) {
    const params = { ...data };
    params.startDate = DateYMD.fromJSON(params.start_date);
    params.startTime = TimeOfDay.fromJSON(params.start_time);
    params.endDate = DateYMD.fromJSON(params.end_date);
    params.endTime = TimeOfDay.fromJSON(params.end_time);
    return new TimeInterval(params);
  }

  requestParams(): TimeIntervalParams {
    return {
      start_date: this.startDate ? this.startDate.requestParams() : null,
      start_time: this.startTime ? this.startTime.requestParams() : null,
      end_date: this.endDate ? this.endDate.requestParams() : null,
      end_time: this.endTime ? this.endTime.requestParams() : null,
    };
  }

  get start() {
    const date = this.startDate;
    const time = this.startTime;
    if (date != null && time != null) {
      const dateText = date.toText();
      const timeText = time.toText();
      return dayjs(`${dateText} ${timeText}`).toDate();
    } else if (date == null && time != null) {
      return time.toDate();
    } else if (date != null && time == null) {
      // 開始日の時間が設定されていない場合は00:00:00として扱う
      const dateText = date.toText();
      const timeText = '00:00:00';
      return dayjs(`${dateText} ${timeText}`).toDate();
    } else {
      return null;
    }
  }

  get end(): Date | null {
    const date = this.endDate;
    const time = this.endTime;
    if (date != null && time != null) {
      const dateText = date.toText();
      const timeText = time.toText();
      return dayjs(`${dateText} ${timeText}`).toDate();
    } else if (date == null && time != null) {
      return time.toDate();
    } else if (date != null && time == null) {
      // 終了日の時間が設定されていない場合は23:59:59として扱う
      const dateText = date.toText();
      const timeText = '23:59:59';
      return dayjs(`${dateText} ${timeText}`).toDate();
    } else {
      return null;
    }
  }
}

export interface EventRecord {
  title: string;
  schedule: TimeInterval;
  showTime: boolean;
}

export class Event extends ImmutableRecord<EventRecord>({
  title: '',
  schedule: new TimeInterval(),
  showTime: false,
}) {
  static fromJSON(data: JSObject = {}) {
    const params = { ...data };
    params.schedule = TimeInterval.fromJSON(params.schedule);
    // startTimeかendTimeが設定されていたらshowTimeの初期値をtrueにする
    params.showTime = params.schedule.startTime != null || params.schedule.endTime != null;
    return new Event(params);
  }

  get scheduleText(): string {
    const { startDate, startTime, endDate, endTime } = this.schedule;
    let startText = '';
    if (startDate) {
      startText += dayjs(startDate.toDate()).format('M月D日');
    }
    if (startTime) {
      startText += dayjs(startTime.toDate()).format(', H:mm');
    }

    let endText = '';
    if (endDate) {
      endText += dayjs(endDate.toDate()).format('M月D日');
    }
    if (endTime) {
      endText += dayjs(endTime.toDate()).format(', H:mm');
    }

    return startText === endText ? `${startText}` : `${startText} - ${endText}`;
  }

  get scheduleTextForCoupon(): string {
    const { startDate, startTime, endDate, endTime } = this.schedule;
    let startText = '';
    if (startDate) {
      startText += dayjs(startDate.toDate()).format('M/D YYYY');
    }
    if (startTime) {
      startText += dayjs(startTime.toDate()).format(', H:mm');
    }

    let endText = '';
    if (endDate) {
      endText += dayjs(endDate.toDate()).format('M/D YYYY');
    }
    if (endTime) {
      endText += dayjs(endTime.toDate()).format(', H:mm');
    }

    return startText === endText ? `${startText}` : `${startText} - ${endText}`;
  }

  requestParams(): PromotionParams['event'] {
    return {
      title: this.title,
      schedule: this.schedule.requestParams(),
    };
  }

  validate() {
    return [
      { type: 'title', error: this.validateTitle() },
      { type: 'start', error: this.validateScheduleStart() },
      { type: 'end', error: this.validateScheduleEnd() },
    ];
  }

  get isValid() {
    const validates = this.validate();
    return (
      validates.filter((target) => {
        return target.error.name;
      }).length === 0
    );
  }

  validateTitle() {
    const errors: JSObject = {};
    if (this.title.length === 0) {
      errors.name = '「タイトル」が必須です';
    }
    if (this.title.length > TITLE_MAX_LENGTH) {
      errors.name = `「タイトル」は${TITLE_MAX_LENGTH}文字以内です`;
    }
    return errors;
  }

  validateScheduleStart() {
    const errors: JSObject = {};
    const start = this.schedule.start;
    const end = this.schedule.end;
    if (start && end && end < start) {
      errors.name = '「開始日」は「終了日」より前を指定してください';
    }
    const startDate = this.schedule.startDate;
    const startTime = this.schedule.startTime;
    if (this.showTime && startTime == null) {
      errors.name = '「開始時間」が必須です';
    }
    if (startDate == null) {
      errors.name = '「開始日」が必須です';
    }
    return errors;
  }

  validateScheduleEnd() {
    const errors: JSObject = {};

    const end = this.schedule.end;
    // 今日の0時(JST)
    const today = dayjs(dayjs().format('YYYY/MM/DD')).toDate();
    // イベントの期間として設定できるのは今日から1年以内（todayから1年後の前日23:59:59まで）
    const eventMaxDate = dayjs(today).add(1, 'year').toDate();
    if (end && end < dayjs().toDate()) {
      errors.name = '「終了日」に過去は指定できません';
    }
    if (end && eventMaxDate <= end) {
      errors.name = '「終了日」は今日から１年以内の日付にしてください';
    }
    const endDate = this.schedule.endDate;
    const endTime = this.schedule.endTime;
    if (this.showTime && endTime == null) {
      errors.name = '「終了時間」が必須です';
    }
    if (endDate == null) {
      errors.name = '「終了日」が必須です';
    }
    return errors;
  }
}

export interface MediaItemRecord {
  name: string;
  mediaFormat: MediaFormat;
  sourceUrl: string;
}

export class MediaItem extends ImmutableRecord<MediaItemRecord>({
  name: '',
  mediaFormat: 'MEDIA_FORMAT_UNSPECIFIED',
  sourceUrl: '',
}) {
  static fromJSON(data: JSObject = {}): MediaItem {
    const params = { ...data };
    return new MediaItem({
      name: params.name ?? '',
      mediaFormat: params.media_format ?? 'MEDIA_FORMAT_UNSPECIFIED',
      sourceUrl: params.source_url ?? '',
    });
  }

  /** メディアが画像か */
  get isImage(): boolean {
    return this.mediaFormat === 'PHOTO';
  }

  /** メディアが動画か */
  get isVideo(): boolean {
    return this.mediaFormat === 'VIDEO';
  }
}

export interface MediaRecord {
  items: ImmutableList<MediaItem>;
}

export class Media extends ImmutableRecord<MediaRecord>({
  items: ImmutableList<MediaItem>(),
}) {
  static fromJSON(data: JSObject[] = []): Media {
    return new Media({
      items: ImmutableList(data.map((d) => MediaItem.fromJSON(d))),
    });
  }

  /** メディアが空か */
  isEmpty(): boolean {
    return this.items.isEmpty();
  }

  /** 1枚目の画像URL */
  get imageUrl(): string | null {
    return this.items.filter((item) => item.isImage).get(0)?.sourceUrl ?? null;
  }

  /** メディアに画像を含んでいるか */
  hasImage(): boolean {
    return this.items.some((item) => item.isImage);
  }

  /** メディアに動画を含んでいるか */
  hasVideo(): boolean {
    return this.items.some((item) => item.isVideo);
  }

  /** すべてのメディアのURLのリスト */
  get sourceUrls(): ImmutableList<string> {
    return this.items.map((item) => item.sourceUrl);
  }

  /** 指定されたインデックスのメディアの削除 */
  deleteItem(index: number): Media {
    return this.update('items', (items) => items.remove(index));
  }
}

export class SourceInstagram extends ImmutableRecord<{
  type: 'instagram';
  permalink: string;
  userName: string;
  name: string;
}>({
  type: 'instagram',
  permalink: '',
  userName: '',
  name: '',
}) {
  static fromJSON(data: JSObject) {
    return new SourceInstagram({
      type: data.type,
      permalink: data.permalink,
      userName: data.ig_username,
      name: data.ig_name,
    });
  }

  requestParams(): PromotionParams['source'] {
    return {
      type: this.type,
      permalink: this.permalink,
      ig_username: this.userName,
      ig_name: this.name,
    };
  }
}

export interface DefaultPromotionRecord {
  _id: string | null;
  organization_id: number | null;
  // 投稿の本文
  body: string;
  // 投稿のメディア
  media: Media;
  // 投稿種別
  topic_type: PromotionTopicType;
  // 投稿のアクションボタン設定
  call_to_action: CallToAction; // 存在しない投稿種別もあるが、実装の簡易化のためnullではなく「なし」のCallToActionを設定する
  event: Event | null;
  offer: Offer | null;
  // 予約投稿か
  isScheduled: boolean;
  // 投稿予定日時
  scheduledAt: Dayjs | null;
  // GBPへの投稿日時
  postedAt: Dayjs | null;
  // 下書きか
  isDraft: boolean;
  // ソース（他のサービス経由の投稿の場合）
  source: SourceInstagram | null;
  // 翻訳データ
  translations: Translations | null;
}

interface PromotionRecord extends DefaultPromotionRecord {
  imageFile: ImageFileMeta | null; // 画像アップロードから登録される画像の検証用の情報。APIリクエストには使わない
  stores: ImmutableList<PromotionStore>;
  create_at: Dayjs;
  update_at: Dayjs;
}

export const defaultPromotionInitialValues: DefaultPromotionRecord = {
  _id: '',
  organization_id: 0,
  body: '',
  media: new Media(),
  topic_type: 'STANDARD',
  call_to_action: new CallToAction(),
  event: null,
  offer: null,
  isScheduled: false,
  scheduledAt: null,
  postedAt: null,
  isDraft: false,
  source: null,
  translations: null,
};

export default class Promotion extends ImmutableRecord<PromotionRecord>({
  ...defaultPromotionInitialValues,
  imageFile: null,
  stores: ImmutableList<PromotionStore>(),
  create_at: dayjs(),
  update_at: dayjs(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    if (params.stores) {
      params.stores = ImmutableList(params.stores.map((store: JSObject) => new PromotionStore(store)));
    }
    if (params.call_to_action) {
      params.call_to_action = new CallToAction(params.call_to_action);
    } else {
      // call_to_actionが存在しない種別でも「なし」として追加しておく
      params.call_to_action = new CallToAction();
    }
    // 投稿詳細ではmediaにメディア情報が入っている
    if (params.media) {
      params.media = Media.fromJSON(params.media);
    }
    // 投稿種別がOFFERかEVENTでparams.eventがない場合は空で作成しておく
    if (params.event) {
      params.event = Event.fromJSON(params.event);
    } else if (params.topic_type === 'EVENT' || params.topic_type === 'OFFER') {
      params.event = new Event();
    } else {
      params.event = null;
    }
    // 投稿種別がOFFERでparams.offerがない場合は空で作成しておく
    if (params.offer) {
      params.offer = Offer.fromJSON(params.offer);
    } else if (params.topic_type === 'OFFER') {
      params.offer = new Offer();
    } else {
      params.offer = null;
    }

    if (params.create_at) {
      params.create_at = dayjs.utc(params.create_at).local();
    }
    if (params.update_at) {
      params.update_at = dayjs.utc(params.update_at).local();
    }

    if (params.is_scheduled) {
      params.isScheduled = params.is_scheduled;
    }
    // APIが返すscheduled_atとposted_atはタイムゾーンが指定されているのでそのまま取り込む
    if (params.scheduled_at) {
      params.scheduledAt = dayjs(params.scheduled_at);
    }
    if (params.posted_at) {
      params.postedAt = dayjs(params.posted_at);
    }
    if (params.is_draft) {
      params.isDraft = params.is_draft;
    }
    if (params.source && params.source.type === 'instagram') {
      params.source = SourceInstagram.fromJSON(params.source);
    }
    if (params.translations) {
      params.translations = Translations.fromJSON(params.translations);
    }

    super(params);
  }

  get isValid() {
    const validates = this.validate();
    return (
      validates.filter((target) => {
        return target.error.name;
      }).length === 0
    );
  }

  get isEditValid() {
    const validates = this.validate();
    return (
      validates.filter((target) => {
        if (target.type === 'store') {
          return false;
        }
        return target.error.name;
      }).length === 0
    );
  }

  /** GBPに掲載されているか */
  get isPosted(): boolean {
    return this.postedAt != null;
  }

  /** 投稿日時として表示する日時(投稿の予定・成否に関わらず投稿を実行した/する日時) */
  get displayPostAt(): Dayjs {
    // GBP投稿済みの投稿は投稿日時を表示する
    // 予約投稿は投稿予定日時を表示する
    // 予約投稿がエラーだった場合は投稿予定日時を投稿日時として表示する
    // それ以外は投稿したがエラーと考えられるので、作成日を投稿日とする
    if (this.postedAt) {
      return this.postedAt;
    } else if (this.scheduledAt) {
      return this.scheduledAt;
    } else {
      return this.create_at;
    }
  }

  /** 投稿が編集可能か */
  get canEdit(): boolean {
    // 下書きなら編集可能
    if (this.isDraft) {
      return true;
    }
    // メディアが複数または動画を含む場合は不可
    if (this.media.items.size > 1 || this.media.hasVideo()) {
      return false;
    }
    // 拒否されていたら編集不可
    return !this.hasGmbStatusReject;
  }

  /** 投稿が画像を利用可能か */
  get canUseImage(): boolean {
    return TOPIC_TYPES_WITH_IMAGE.includes(this.topic_type);
  }

  /** 投稿がCallToActionを利用可能か */
  get canUseCallToAction(): boolean {
    return TOPIC_TYPES_WITH_CALL_TO_ACTION.includes(this.topic_type);
  }

  /** 投稿がイベント情報を利用可能か */
  get canUseEvent(): boolean {
    return TOPIC_TYPES_WITH_EVENT.includes(this.topic_type);
  }

  /** 投稿が特典情報を利用可能か */
  get canUseOffer(): boolean {
    return TOPIC_TYPES_WITH_OFFER.includes(this.topic_type);
  }

  /** URLの入力が可能なボタンタイプ */
  get canInputUrl(): boolean {
    return this.call_to_action.canInputUrl;
  }

  /** エイリアスを除いたURL */
  get urlWithoutAlias() {
    return this.call_to_action.urlWithoutAlias;
  }

  /** 全ての店舗がgmbに投稿完了している */
  get isGmbStatusSuccess() {
    return this.stores.find((target) => target.gmb_state !== 'LIVE') === undefined;
  }

  /** rejectの投稿を含んでいる */
  get hasGmbStatusReject() {
    return this.stores.find((store) => store.gmb_state === 'REJECTED') !== undefined;
  }

  /** gmbに投稿が完了している店舗の数 */
  get gmbStatusSuccessCount() {
    const result = this.stores.filter((target) => target.gmb_state === 'LIVE');
    return result.size;
  }

  validate() {
    return [
      { type: 'store', error: this.validateStore() },
      { type: 'body', error: this.validateBody() },
      { type: 'image', error: this.validateImage() },
      { type: 'url', error: this.validateUrl() },
      { type: 'event', error: this.validateEvent() },
      { type: 'offer', error: this.validateOffer() },
      { type: 'schedule', error: this.validateSchedule() },
    ];
  }

  validateStore() {
    const errors: JSObject = {};
    if (this.stores.isEmpty()) {
      errors.name = '投稿先の店舗は最低1店舗必要です';
    }
    return errors;
  }

  validateBody() {
    const errors: JSObject = {};

    // bodyから利用可能なエイリアスを削除
    const aliasRemovedBody = removeAll(this.body, AvailableAliases.body);

    switch (this.topic_type) {
      case 'STANDARD':
        if ((!aliasRemovedBody || !aliasRemovedBody.trim()) && !this.media.hasImage()) {
          errors.name = '「本文」または「画像」が必須です';
        }
        if (this.body.length > BODY_MAX_LENGTH) {
          errors.name = `「本文」は${BODY_MAX_LENGTH}文字以内です`;
        }
        break;
      case 'ALERT':
        if (!this.body || !this.body.trim()) {
          errors.name = '「ステータス」が必須です';
        }
        if (this.body.length > BODY_MAX_LENGTH) {
          errors.name = `「ステータス」は${BODY_MAX_LENGTH}文字以内です`;
        }
        break;
      case 'EVENT':
        if (this.body.length > BODY_MAX_LENGTH) {
          errors.name = `「イベントの詳細」は${BODY_MAX_LENGTH}文字以内です`;
        }
        break;
      case 'OFFER':
        if (this.body.length > BODY_MAX_LENGTH) {
          errors.name = `「特典の詳細」は${BODY_MAX_LENGTH}文字以内です`;
        }
        break;
    }
    return errors;
  }

  validateImage() {
    const errors: JSObject = {};
    switch (this.topic_type) {
      case 'STANDARD':
      case 'OFFER':
      case 'EVENT':
        // 画像ファイルが追加されていなければ、画像ファイルに関するバリデーションを行わない
        if (this.imageFile) {
          if (!VALID_IMAGE_FILE_TYPES.includes(this.imageFile.type)) {
            errors.name = '指定可能な画像ファイルの形式はJPEGまたはPNGのみです';
          } else if (this.imageFile.size < 10 * 1024 || this.imageFile.size > 25 * 1000 * 1000) {
            errors.name = '指定可能な画像ファイルのサイズは10KBから25MBまでです';
          } else if (
            this.imageFile.width < 400 ||
            this.imageFile.width > 10000 ||
            this.imageFile.height < 300 ||
            this.imageFile.height > 10000
          ) {
            errors.name = '指定可能な画像ファイルのピクセル数は縦300~10000px、横400~10000pxまでです';
          }
        }
        if (!this.media.items.isEmpty()) {
          if (this.media.items.size > 1) {
            errors.name = '指定可能なファイルは１つのみです';
          } else if (this.media.hasVideo()) {
            errors.name = '指定可能なファイルは画像のみです';
          }
        }
        break;
      case 'ALERT':
        if (!this.media.items.isEmpty()) {
          errors.name = 'COVID-19の最新情報では「画像」を追加できません';
        }
        break;
    }
    return errors;
  }

  validateUrl() {
    const errors: JSObject = {};
    if (!this.canInputUrl) {
      return {};
    }
    const url = this.call_to_action.url;
    if (validateWebsiteUrl(url)) {
      // AliasLinkTypeは単体で有効なURLなので、httpから始まるURLで使われているとエラー
      if (AliasLinkType.some((alias) => url.includes(alias))) {
        errors.name = '有効なリンクを入力してください';
      }
    } else {
      // AliasLinkTypeからはじまる場合はエラーにならない
      // パラメータなどを付与するために、差し込み変数のあとの文字列は許容する
      if (!AliasLinkType.some((alias) => url.startsWith(alias))) {
        errors.name = '有効なリンクを入力してください';
      }
    }
    return errors;
  }

  validateEvent() {
    const errors: JSObject = {};
    switch (this.topic_type) {
      case 'EVENT':
      case 'OFFER':
        if (this.event && !this.event.isValid) {
          errors.name = 'イベントが不正です';
        } else if (this.event == null) {
          errors.name = 'イベントが設定されていません';
        }
        break;
      case 'STANDARD':
      case 'ALERT':
        // イベントの項目なし
        break;
    }
    return errors;
  }

  validateOffer() {
    const errors: JSObject = {};
    switch (this.topic_type) {
      case 'OFFER':
        if (this.offer && !this.offer.isValid) {
          errors.name = '特典が不正です';
        } else if (this.offer == null) {
          errors.name = '特典が設定されていません';
        }
        break;
      case 'STANDARD':
      case 'ALERT':
      case 'EVENT':
        // 特典の項目なし
        break;
    }
    return errors;
  }

  validateSchedule() {
    const errors: JSObject = {};
    // 今日の0時(JST)
    const today = dayjs(dayjs().format('YYYY/MM/DD'));
    // 投稿日時として設定できるのは今日から1年以内（todayから1年後の前日23:59:59まで）
    const maxDate = dayjs(today).add(1, 'year');
    const eventEnd = this.event ? this.event.schedule.end : null;

    if (this.isScheduled) {
      if (!this.scheduledAt) {
        errors.name = '「投稿日時」が設定されていません';
      } else if (this.scheduledAt < dayjs()) {
        errors.name = '「投稿日時」に過去の日時は設定できません';
      } else if (this.scheduledAt >= maxDate) {
        errors.name = '「投稿日時」は今日から１年以内の日付にしてください';
      } else if (eventEnd && eventEnd < this.scheduledAt.toDate()) {
        errors.name = '「投稿日時」は「イベント終了日」より前の日付にしてください';
      }
    }
    return errors;
  }

  changeBody(body: string) {
    return this.set('body', body);
  }

  changeMedia(media: Media) {
    return this.set('media', media);
  }

  changeCallToActionActionType(action_type: string) {
    return this.setIn(['call_to_action', 'action_type'], action_type);
  }

  changeCallToActionUrl(url: string) {
    return this.setIn(['call_to_action', 'url'], url);
  }

  changeStores(storeIds: number[]) {
    return this.set('stores', ImmutableList(storeIds.map((storeId) => new PromotionStore({ store_id: storeId }))));
  }

  changeImageFile(imageFile: ImageFileMeta | null) {
    return this.set('imageFile', imageFile);
  }

  changeEventTitle(title: string) {
    return this.setIn(['event', 'title'], title);
  }

  changeShowEventTime(value: boolean) {
    let newPromotion = this.setIn(['event', 'showTime'], value);
    newPromotion = newPromotion.setIn(['event', 'schedule', 'startTime'], null);
    newPromotion = newPromotion.setIn(['event', 'schedule', 'endTime'], null);
    return newPromotion;
  }

  changeEventStartDate(date: Date | null) {
    const startDate = DateYMD.fromDate(date);
    return this.setIn(['event', 'schedule', 'startDate'], startDate);
  }

  changeEventStartTime(date: Date | null) {
    const startTime = TimeOfDay.fromDate(date);
    return this.setIn(['event', 'schedule', 'startTime'], startTime);
  }

  changeEventEndDate(date: Date | null) {
    const endDate = DateYMD.fromDate(date);
    return this.setIn(['event', 'schedule', 'endDate'], endDate);
  }

  changeEventEndTime(date: Date | null) {
    const endTime = TimeOfDay.fromDate(date);
    return this.setIn(['event', 'schedule', 'endTime'], endTime);
  }

  changeOfferCouponCode(code: string) {
    return this.setIn(['offer', 'couponCode'], code);
  }

  changeOfferRedeemOnlineUrl(url: string) {
    return this.setIn(['offer', 'redeemOnlineUrl'], url);
  }

  changeOfferTermsConditions(text: string) {
    return this.setIn(['offer', 'termsConditions'], text);
  }

  changeIsScheduled(value: boolean): this {
    // 予約投稿かどうか切り替えたらscheduledAtをリセットする
    return this.merge({ isScheduled: value, scheduledAt: null });
  }

  changeScheduledAt(value: Dayjs): this {
    return this.set('scheduledAt', value);
  }

  changeIsDraft(value: boolean): this {
    return this.set('isDraft', value);
  }

  requestParams(stores: Stores): PromotionPostParams['promotions'] {
    return {
      _id: this._id,
      organization_id: this.organization_id,
      body: this.body,
      media: this.media.items
        .map((item) => ({
          source_url: item.sourceUrl,
          media_format: item.mediaFormat,
          name: item.name,
        }))
        .toArray(),
      store_ids: this.stores
        .filter((t) => {
          const targetStore = stores.findStore(t.store_id);
          if (!targetStore) {
            return false;
          }
          return targetStore.location_state.can_use_local_post;
        })
        .map((store) => store.store_id)
        .toArray(),
      topic_type: this.topic_type,
      // 指定されている場合のみcall_to_action, event, offerを追加
      call_to_action: this.call_to_action.isSpecified ? this.call_to_action.requestParams() : null,
      event: this.event ? this.event.requestParams() : null,
      offer: this.offer ? this.offer.requestParams() : null,
      is_scheduled: this.isScheduled,
      is_draft: this.isDraft,
      scheduled_at: this.scheduledAt?.local().format() ?? null, // 2000-01-01T00:00:00+09:00
      source: this.source ? this.source.requestParams() : null,
      translations: null,
    };
  }

  get topicTypeLabel(): string {
    return PromotionTopicTypeLabel[this.topic_type];
  }

  getStoreIds() {
    return this.stores
      .map((s) => s.store_id)
      .filter((id) => id != null)
      .toArray() as number[]; // id != null してるのに、なぜか型がnullを含むので as number[] している
  }

  /** 指定された店舗のリストで設定可能な項目のエイリアスを返す */
  static getAvailableAliasTypes(key: keyof typeof AvailableAliases, stores: ImmutableList<Store>): AliasType[] {
    // 「予約リンク」は店舗のカテゴリによっては設定できない項目なので、予約リンクを含む店舗が存在する場合のみ利用可能にする
    // Stores側に実装する方法もあるが、投稿のエイリアスについてStoresに持たせたくなかったので、Promotion側に実装している
    const hasAppointmentUrlStores = stores
      .filter((s) => s)
      .some((s) => s.location.urlAttributes.find('url_appointment')?.hasValue() ?? false);
    switch (key) {
      case 'body':
        return hasAppointmentUrlStores
          ? AvailableAliases.body
          : AvailableAliases.body.filter((t) => t !== '{{予約リンク}}');
      case 'url':
        return hasAppointmentUrlStores
          ? AvailableAliases.url
          : AvailableAliases.url.filter((t) => t !== '{{予約リンク}}');
    }
  }

  getContainAliasTypes(key: keyof typeof AvailableAliases) {
    switch (key) {
      case 'body':
        return AvailableAliases.body.filter((alias) => this.body.includes(alias));
      case 'url':
        return AvailableAliases.url.filter((alias) => this.call_to_action.url.includes(alias));
    }
  }

  containsAlias(key: keyof typeof AvailableAliases) {
    return this.getContainAliasTypes(key).length > 0;
  }

  /**
   * 選択されている店舗で利用されているエイリアスが存在しない場合の警告メッセージを生成する
   */
  getWarningMessages(stores: ImmutableList<Store>) {
    const bodyWarnings = [];
    const bodyAvailableAliases = Promotion.getAvailableAliasTypes('body', stores);

    // 利用できないエイリアスが含まれていないか
    const aliasRemovedBody = removeAll(this.body, bodyAvailableAliases);
    if (aliasRemovedBody.includes('{{') || aliasRemovedBody.includes('}}')) {
      bodyWarnings.push(`利用可能な差し込み変数は、${bodyAvailableAliases.join('、')} です。`);
    }

    const bodyAliases = bodyAvailableAliases.filter((alias) => this.body.includes(alias));
    if (bodyAliases.includes('{{支店名}}')) {
      const warningStores = stores.filter((s) => !s.branch);
      if (warningStores.size > 0) {
        bodyWarnings.push(
          `{{支店名}} が含まれていますが、「${warningStores.get(0)?.fullName}」${
            warningStores.size === 1 ? 'に' : `など${warningStores.size}店舗で`
          }支店名が設定されていません。支店名が設定されていない店舗に関しては、{{支店名}} に値は入力されません。`,
        );
      }
    }

    if (bodyAliases.includes('{{予約リンク}}')) {
      const warningStores = stores.filter((s) => !s.location.urlAttributes.find('url_appointment')?.hasValue());
      if (warningStores.size > 0) {
        bodyWarnings.push(
          `{{予約リンク}} が含まれていますが、「${warningStores.get(0)?.fullName}」${
            warningStores.size == 1 ? 'に' : `など${warningStores.size}店舗で`
          }予約リンクが設定されていません。予約リンクが設定されていない店舗に関しては、{{予約リンク}} に値は入力されません。`,
        );
      }
    }

    if (bodyAliases.includes('{{予約リンク}}')) {
      const warningStores = stores.filter((s) => {
        const urlAppointmentSize = s.location.urlAttributes.find('url_appointment')?.urlValues?.size;
        return urlAppointmentSize && urlAppointmentSize > 1;
      });
      if (warningStores.size > 0) {
        bodyWarnings.push(
          `{{予約リンク}} が含まれていますが、「${warningStores.get(0)?.fullName}」${
            warningStores.size == 1 ? 'に' : `など${warningStores.size}店舗で`
          }予約リンクが複数設定されています。予約リンクが複数設定されている店舗に関しては、１つ目に設定した予約リンクが入力されます。`,
        );
      }
    }

    if (bodyAliases.includes('{{ウェブサイト}}')) {
      const warningStores = stores.filter((s) => !s.location.websiteUrl);
      if (warningStores.size > 0) {
        bodyWarnings.push(
          `{{ウェブサイト}} が含まれていますが、「${warningStores.get(0)?.fullName}」${
            warningStores.size == 1 ? 'に' : `など${warningStores.size}店舗で`
          }ウェブサイトが設定されていません。ウェブサイトが設定されていない店舗に関しては、{{ウェブサイト}} に値は入力されません。`,
        );
      }
    }

    const urlWarnings: string[] = [];

    const url = this.call_to_action.url;
    const urlAvailableAliases = Promotion.getAvailableAliasTypes('url', stores);

    // 利用できないエイリアスが含まれていないか
    // bodyから利用可能なエイリアスを削除
    const aliasRemovedText = removeAll(url, urlAvailableAliases);
    if (aliasRemovedText.includes('{{') || aliasRemovedText.includes('}}')) {
      urlWarnings.push(`利用可能な差し込み変数は、${urlAvailableAliases.join('、')} です。`);
    }

    const urlAliases = urlAvailableAliases.filter((alias) => url.includes(alias));
    if (urlAliases.includes('{{店舗コード}}')) {
      const warningStores = stores.filter((s) => !s.code);
      if (warningStores.size > 0) {
        urlWarnings.push(
          `{{店舗コード}} が含まれていますが、「${warningStores.get(0)?.fullName}」${
            warningStores.size == 1 ? 'に' : `など${warningStores.size}店舗で`
          }店舗コードが設定されていません。店舗コードが設定されていない店舗に関しては、{{店舗コード}} に値は入力されません。`,
        );
      }
    }

    if (urlAliases.includes('{{予約リンク}}')) {
      const warningStores = stores.filter((s) => {
        const urlAppointmentSize = s.location.urlAttributes.find('url_appointment')?.urlValues?.size;
        return urlAppointmentSize && urlAppointmentSize > 1;
      });
      if (warningStores.size > 0) {
        urlWarnings.push(
          `{{予約リンク}} が含まれていますが、「${warningStores.get(0)?.fullName}」${
            warningStores.size == 1 ? 'に' : `など${warningStores.size}店舗で`
          }予約リンクが複数設定されています。予約リンクが複数設定されている店舗に関しては、１つ目に設定した予約リンクが入力されます。`,
        );
      }
    }

    return { body: bodyWarnings, url: urlWarnings };
  }

  getErrorMessages(stores: ImmutableList<Store>) {
    const urlErrors: string[] = [];

    const url = this.call_to_action.url;

    const urlAliases = AvailableAliases.url.filter((alias) => url.includes(alias));

    if (urlAliases.includes('{{予約リンク}}')) {
      const errorStores = stores.filter((s) => !s.location.urlAttributes.find('url_appointment')?.hasValue());
      if (errorStores.size > 0) {
        urlErrors.push(
          `{{予約リンク}} が含まれていますが、「${errorStores.get(0)?.fullName}」${
            errorStores.size == 1 ? 'に' : `など${errorStores.size}店舗で`
          }予約リンクが設定されていません。投稿先のすべての店舗に予約リンクの設定が必要です。`,
        );
      }
    }

    if (urlAliases.includes('{{ウェブサイト}}')) {
      const errorStores = stores.filter((s) => s.location.websiteUrl === '');
      if (errorStores.size > 0) {
        urlErrors.push(
          `{{ウェブサイト}} が含まれていますが、「${errorStores.get(0)?.fullName}」${
            errorStores.size == 1 ? 'に' : `など${errorStores.size}店舗で`
          }ウェブサイトが設定されていません。投稿先のすべての店舗にウェブサイトの設定が必要です。`,
        );
      }
    }
    return { url: urlErrors };
  }

  /**
   * bodyのtype(Alias, Text, PhoneNumber, NGWord)の注釈付きデータを返す
   */
  getAnnotatedBody(stores: ImmutableList<Store>) {
    // コンテンツポリシーに反する可能性のあるワード
    const contentsWarnings = checkBodyContentsPolicy(this.body);
    return getAnnotatedBody(this.body, stores, contentsWarnings);
  }

  generateAliasLabel(store: Store) {
    const targetBody = this.getAnnotatedBody(ImmutableList([store]));
    return targetBody.map((data) => {
      if (data.type == 'Alias') {
        if (data.text === '{{店名}}') {
          return { type: data.type, text: store.name };
        } else if (data.text === '{{支店名}}') {
          return { type: data.type, text: store.branch };
        } else if (data.text === '{{予約リンク}}') {
          // 予約リンクが複数設定されている場合は１つ目のURLを表示する
          const appointmentUrls = store.location.urlAttributes.find('url_appointment')?.urlValues;
          const firstAppointmentUrl = appointmentUrls?.getIn([0, 'url']) ?? '';
          return { type: data.type, text: firstAppointmentUrl };
        } else if (data.text === '{{ウェブサイト}}') {
          return { type: data.type, text: store.location.websiteUrl };
        }
      }
      return data;
    });
  }

  /** 店舗の投稿状態を追加 */
  setStoreSummary(value: PromotionSummaryDetailResponse) {
    return this.set(
      'stores',
      ImmutableList(
        this.stores.map((target: PromotionStore) => {
          const resultSummary = value.find((postStatus) => target.store_id === postStatus.store_id);
          if (!resultSummary) {
            return target;
          }
          return target.merge({
            state: resultSummary.state,
            gmb_state: resultSummary.gmb_state,
          });
        }),
      ),
    );
  }

  /**
   * gmbに投稿できない店舗一覧
   */
  getCanNotPostGmbPromotionStores(stores: Stores) {
    return this.stores.filter((t) => {
      const targetStore = stores.findStore(t.store_id);
      if (!targetStore) {
        return false;
      }
      return !targetStore.location_state.can_use_local_post;
    });
  }

  getContentsPolicyWarningMessages() {
    const checkResults = checkBodyContentsPolicy(this.body);

    const warnings: string[] = [];

    const phoneNumbers = Object.entries(checkResults)
      .filter(([text, type]) => type === 'PhoneNumber')
      .map(([text, type]) => text);
    if (phoneNumbers.length > 0) {
      warnings.push(
        `電話番号 ${phoneNumbers
          .map((t) => `「${t}」`)
          .join('、')} が含まれています。投稿コンテンツに電話番号を含めることは許可されていません。`,
      );
    }

    const ngWords = Object.entries(checkResults)
      .filter(([text, type]) => type === 'NGWord')
      .map(([text, type]) => text);
    if (ngWords.length > 0) {
      warnings.push(`${ngWords.map((t) => `「${t}」`).join('、')} を含む投稿は非公開になる可能性があります。`);
    }

    return warnings;
  }
}

class NGWordCache {
  private static ngwords: string[] | null = null;

  /**
   * 投稿用のNGワードを取得する
   *
   * まだデータが取得されていない場合は空配列が返るので注意
   * NGワードが必須というわけではないので処理をシンプルにするためにこのような形式にしている。
   * 非同期処理を同期処理から分離することで、コンポーネントで取得して引数で渡したり、またはasync/awaitが連鎖しなくて済む。
   */
  public static getNGWords() {
    if (!NGWordCache.ngwords) {
      NGWordCache.fetchNGWords();
    }
    return NGWordCache.ngwords || [];
  }

  public static fetchNGWords() {
    return axios
      .get<string[]>(CONFIG.LOCALPOST_NGWORDS_URL)
      .then((res) => {
        NGWordCache.ngwords = deobfuscate(res.data);
      })
      .catch((error) => {
        console.error(error);
        NGWordCache.ngwords = [];
      });
  }
}

// NGワードは微妙なワードが多く、デバッグコンソールのネットワークで内容を見られると微妙なので
// 以下のメソッドを用いて簡易的に難読化する。
// https://jsfiddle.net/7ms8r2wc/7/

// const obfuscate = (data: any): string => {
//   const _obfuscate = (_data: any) => btoa(encodeURIComponent(JSON.stringify(_data)));
//   let obfuscated_data = data;
//   for (let i = 1; i < 3; i++) {
//     obfuscated_data = _obfuscate(obfuscated_data);
//   }
//   return obfuscated_data;
// };

const deobfuscate = (data: any) => {
  const _deobfuscate = (_data: any) => JSON.parse(decodeURIComponent(atob(_data)));
  let deobfuscated_data = data;
  for (let i = 1; i < 3; i++) {
    deobfuscated_data = _deobfuscate(deobfuscated_data);
  }
  return deobfuscated_data;
};

/**
 * bodyの内容からコンテンツポリシーに反する可能性のあるワードを抽出します
 *
 * ビジネス プロフィールの写真や動画に関するポリシーと投稿に関するコンテンツ ポリシー
 * https://support.google.com/business/answer/7213077?hl=ja
 */
export const checkBodyContentsPolicy = (body: string) => {
  const results: { [x: string]: 'PhoneNumber' | 'NGWord' } = {};
  const phoneNumbers = extractPhoneNumber(body);
  phoneNumbers.forEach((phoneNumber) => {
    results[phoneNumber] = 'PhoneNumber' as const;
  });

  const ngWords = NGWordCache.getNGWords();
  extractContainWords(body, ngWords, true, true).forEach((ngWord) => {
    results[ngWord] = 'NGWord' as const;
  });

  return results;
};

/**
 * bodyのtype(Alias, Text, PhoneNumber, NGWord)の注釈付きデータを返す
 */
export const getAnnotatedBody = (
  body: string,
  stores: ImmutableList<Store>,
  contentsWarnings: {
    [x: string]: 'PhoneNumber' | 'NGWord';
  },
) => {
  const availableAliases = Promotion.getAvailableAliasTypes('body', stores);

  // コンテンツポリシーに反する可能性のあるワード
  const contentsWarningWords = Object.keys(contentsWarnings);

  // まとめて分割する
  const annotationTargets = [...availableAliases, ...contentsWarningWords];
  const splitPattern = new RegExp(`(${annotationTargets.join('|')})`);
  return body.split(splitPattern).map((text) => {
    if ((availableAliases as string[]).includes(text)) {
      return { type: 'Alias' as const, text };
    } else if (contentsWarningWords.includes(text)) {
      return { type: contentsWarnings[text], text };
    }
    return { type: 'Text' as const, text };
  });
};
