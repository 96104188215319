import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import { ExternalLink } from 'components/atoms/ExternalLink';
import { GoogleMap } from 'components/atoms/GoogleMap';
import { Link } from 'components/atoms/Link';
import { ConfirmModal } from 'components/molecules/ConfirmModal';
import { ProgressCircle } from 'components/molecules/ProgressCircle';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { AttributeList } from 'components/organisms/AttributeList';
import { GmbConnectModal } from 'components/organisms/GmbConnectModal';
import { MoreHoursList } from 'components/organisms/MoreHoursLists';
import { EditStoreItemModal, EditStoreItemModalType } from 'components/pageComponents/StoreDetail/EditStoreItemModal';
import { GmbLatlngImportModal } from 'components/pageComponents/StoreDetail/GmbLatlngImportModal';
import { StoreDeleteModal } from 'components/pageComponents/StoreDetail/StoreDeleteModal';
import { StoreItemCard } from 'components/pageComponents/StoreDetail/StoreItemCard';
import { UrlAttributeList } from 'components/pageComponents/StoreDetail/UrlAttributeList';
import { GUIDE_LINKS } from 'helpers/utils';
import { GmbActions } from 'modules/gmb/actions';
import { StoreDetailActions } from 'modules/storeDetail/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';
import { Mixins } from 'style/mixin';

type Props = { storeId: string };

// 緯度経度を表示する際のGoogleマップのズームレベル
// Googleビジネスプロフィールの住所編集画面より2回寄った状態を指定
const LATLNG_MAP_ZOOM_LEVEL = 17;

// 設定を推奨する項目
const STORE_CHECK_ITEMS = ['店舗名', '住所', '電話番号', '営業時間', 'ウェブサイト', 'ビジネス情報'] as const;

export const StoreDetailContents = React.memo<Props>(({ storeId }) => {
  const dispatch = useDispatch();

  const [editModalState, setEditModalState] = useState<{ isOpen: boolean; type: EditStoreItemModalType }>({
    isOpen: false,
    type: 'code',
  });

  const [gmbModalState, setGmbModalState] = useState<boolean>(false);

  const [deleteModalState, setDeleteModalState] = useState<boolean>(false);

  const [importGmbMapLatlngState, setImportGmbMapLatlngState] = useState<boolean>(false);

  const {
    storeForDetail,
    storeForEdit,
    currentUser,
    gmbCategoryList,
    attributeMetadatas,
    requireManualSettingItems,
    isSuccessAddressValidation,
    competitors,
  } = useSelector(
    (state) => ({
      currentUser: state.app.currentUser,
      gmbCategoryList: state.gmb.categoryList,
      storeForDetail: state.storeDetail.storeForDetail,
      storeForEdit: state.storeDetail.storeForEdit,
      attributeMetadatas: state.storeDetail.attributeMetadatas,
      requireManualSettingItems: state.storeDetail.requireManualSettingItems,
      isSuccessAddressValidation: state.storeDetail.isSuccessAddressValidation,
      competitors: state.storeDetail.competitors,
    }),
    shallowEqual,
  );

  const onOpenEditModal = useCallback((type: EditStoreItemModalType) => {
    setEditModalState({ type, isOpen: true });
  }, []);

  const onCloseEditModal = useCallback(() => {
    setEditModalState({ ...editModalState, isOpen: false });
    dispatch(StoreDetailActions.resetStoreForEdit());
  }, [dispatch, editModalState]);

  const onDelete = useCallback(() => {
    dispatch(StoreDetailActions.deleteStore());
  }, [dispatch]);

  const toggleDeleteModal = useCallback(() => {
    setDeleteModalState(!deleteModalState);
  }, [deleteModalState, setDeleteModalState]);

  const updateStore = useCallback(() => {
    const { type } = editModalState;
    switch (type) {
      case 'openInfo':
        dispatch(StoreDetailActions.updateStoreOpenInfo());
        break;
      case 'openingDate':
        dispatch(StoreDetailActions.updateStoreOpeningDate());
        break;
      case 'code':
        dispatch(StoreDetailActions.updateStoreCode());
        break;
      case 'phone':
        dispatch(StoreDetailActions.updatePhone());
        break;
      case 'name':
        dispatch(StoreDetailActions.updateStoreNameBranch());
        break;
      case 'websiteUrl':
        dispatch(StoreDetailActions.updateStoreWebsiteUrl());
        break;
      case 'address':
        dispatch(StoreDetailActions.updateStoreAddress());
        break;
      case 'regularHours':
        dispatch(StoreDetailActions.updateStoreRegularHours());
        break;
      case 'specialHours':
        dispatch(StoreDetailActions.updateStoreSpecialHours());
        break;
      case 'moreHours':
        dispatch(StoreDetailActions.updateMoreHours());
        break;
      case 'category':
        dispatch(StoreDetailActions.updateStoreGmbCategories());
        break;
      case 'profile':
        dispatch(StoreDetailActions.updateStoreGmbProfile());
        break;
      case 'attributes':
      case 'urlAttributes':
        dispatch(StoreDetailActions.updateStoreAttributes());
        break;
      default:
        break;
    }
    onCloseEditModal();
  }, [dispatch, editModalState, onCloseEditModal]);

  useEffect(() => {
    dispatch(StoreDetailActions.getStore(Number(storeId)));
    dispatch(GmbActions.getGmbCategoryList());
  }, [dispatch, storeId]);

  const toggleImportGmbMapLatlngState = useCallback(() => {
    setImportGmbMapLatlngState(!importGmbMapLatlngState);
  }, [importGmbMapLatlngState, setImportGmbMapLatlngState]);

  const importGmbMapLatlng = useCallback(() => {
    dispatch(StoreDetailActions.importGmbMapLatlng());
    toggleImportGmbMapLatlngState();
  }, [dispatch, toggleImportGmbMapLatlngState]);

  const storeCheckedItems = useMemo(() => {
    return STORE_CHECK_ITEMS.map((item) => {
      switch (item) {
        case '店舗名':
          return { name: item, isDone: !!storeForDetail.name };
        case '住所':
          return { name: item, isDone: !!storeForDetail.location.address.address1 };
        case '電話番号':
          return { name: item, isDone: !!storeForDetail.location.primaryPhone };
        case '営業時間':
          return { name: item, isDone: !!storeForDetail.location.regularHours };
        case 'ウェブサイト':
          return { name: item, isDone: !!storeForDetail.location.websiteUrl };
        case 'ビジネス情報':
          return { name: item, isDone: !!storeForDetail.location.profile.description };
      }
    });
  }, [storeForDetail]);

  const storeCheckedDoneItems = storeCheckedItems.filter((item) => item.isDone);
  const doneItemRate = (storeCheckedDoneItems.length / STORE_CHECK_ITEMS.length) * 100;

  if (
    !storeForDetail.isExist ||
    !storeForEdit.isExist ||
    storeForDetail.id !== Number(storeId) ||
    storeForEdit.id !== Number(storeId)
  ) {
    return null;
  }

  const {
    name,
    branch,
    code,
    is_connected_gmb,
    location_state,
    location: {
      primaryPhone,
      additionalPhones,
      websiteUrl,
      regularHoursForDisplay,
      specialHoursForDisplay,
      address,
      primaryCategory,
      additionalCategories,
      openInfo,
      profile,
      attributes,
      urlAttributes,
      latlng,
      moreHours,
      availableMoreHoursTypes,
      openingDate,
    },
    location_state: { gmb_address_patch_failed },
  } = storeForDetail;
  const showEditButton = storeForDetail.hasApproveAuth(currentUser);
  const showDeleteStoreButton = currentUser.canDeleteStore && !storeForDetail.is_connected_gmb;

  return (
    <>
      <Wrapper>
        <StoreItemsCheckWrapper>
          <StoreItemsCheckGraph>
            <StoreItemsCheckGraphTitle>推奨項目の設定率</StoreItemsCheckGraphTitle>
            <StoreItemsCheckGraphContent>
              <ProgressCircle value={doneItemRate} size={'200'} />
            </StoreItemsCheckGraphContent>
          </StoreItemsCheckGraph>
          <StoreItemsCheckTable>
            <Table unstackable={true}>
              <TableHeader>
                <TableHeaderRow>
                  <TableHeaderCell>項目</TableHeaderCell>
                  <TableHeaderCell />
                </TableHeaderRow>
              </TableHeader>
              <TableBody>
                {storeCheckedItems.map((item, index) => (
                  <TableRow key={index}>
                    <StoreItemNameCell>{item.name}</StoreItemNameCell>
                    <StoreItemValueCell>{item.isDone ? '○' : 'ー'}</StoreItemValueCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StoreItemsCheckTable>
        </StoreItemsCheckWrapper>

        <GmbCardWrapper>
          <StoreItemCard
            labelName='Googleビジネスプロフィール'
            showEditButton={showEditButton && !currentUser.isMemberUser}
            onClick={() => dispatch(GmbActions.openConnectModal(storeForDetail))}
          >
            <GmbConnectText is_connected_gmb={is_connected_gmb}>
              {is_connected_gmb
                ? 'Googleビジネスプロフィールと連携済みです'
                : 'Googleビジネスプロフィールと連携していません'}
            </GmbConnectText>
          </StoreItemCard>
          {is_connected_gmb && !currentUser.isMemberUser && (
            <GmbRemove>
              <div onClick={() => setGmbModalState(true)}>Googleビジネスプロフィールとの連携を解除する</div>
            </GmbRemove>
          )}
        </GmbCardWrapper>

        {is_connected_gmb && location_state.isGmbError && (
          <Alert type='error'>
            <Alert.Title>投稿やクチコミ、画像連携機能などを利用するためには問題の解決が必要です。</Alert.Title>
            <Alert.Section>
              <Alert.Content>
                この店舗は現在、Googleビジネスプロフィールが
                {location_state.errorLabels.map((label, i) => (
                  <GmbErrorReason key={i}>{label}</GmbErrorReason>
                ))}
                ため、投稿やクチコミ、画像連携などが実施できません。
                <br />
                これらの操作を実施するためには、原因を確認して対応してください。
                <br />
                <br />※
                原因がわからない場合や解決しない場合、Googleビジネスプロフィール担当もしくは、画面左下のサポートからお問い合わせください。
                <br />※
                Googleビジネスプロフィールの問題が解決してからSTORECASTに反映されるまで最大24時間かかることがあります。
              </Alert.Content>
            </Alert.Section>
          </Alert>
        )}

        <CardListWrapper>
          <StoreItemCard
            labelName='店舗名'
            showEditButton={showEditButton}
            onClick={() => onOpenEditModal('name')}
            isRecommend
          >
            <StoreName>{name}</StoreName>
            {branch && <StoreBranch>{branch}</StoreBranch>}
          </StoreItemCard>
          <StoreItemCard
            labelName='営業ステータス'
            showEditButton={showEditButton}
            onClick={() => onOpenEditModal('openInfo')}
          >
            {openInfo.statusName}
          </StoreItemCard>
          <StoreItemCard
            labelName={'開業日'}
            showEditButton={showEditButton}
            onClick={() => onOpenEditModal('openingDate')}
          >
            {openingDate ? openingDate.displayDate : '未設定'}
          </StoreItemCard>
          <StoreItemCard
            labelName='カテゴリー'
            showEditButton={showEditButton}
            onClick={() => onOpenEditModal('category')}
          >
            <SubLabel>メインカテゴリー</SubLabel>
            <div>{gmbCategoryList.generateDisplayName(primaryCategory.categoryId)}</div>
            <SubLabel>サブカテゴリー</SubLabel>

            {additionalCategories.list.map((additionalCategory, idx) => {
              return <div key={idx}>{gmbCategoryList.generateDisplayName(additionalCategory.categoryId)}</div>;
            })}
          </StoreItemCard>
          <StoreItemCard labelName='店舗コード' showEditButton={showEditButton} onClick={() => onOpenEditModal('code')}>
            {code}
          </StoreItemCard>
          <StoreItemCard
            labelName='所在地'
            showEditButton={showEditButton}
            onClick={() => onOpenEditModal('address')}
            isRecommend
          >
            <div>{address?.postalCode}</div>
            <div>
              {address?.administrativeArea}
              {address?.address1}
            </div>
            <div>{address?.address2}</div>
            {(requireManualSettingItems.has('address') ||
              // 住所のバリデーションが成功してる場合、gmb_address_patch_failedがtrueでも警告メッセージは表示しない
              (gmb_address_patch_failed && !isSuccessAddressValidation)) && (
              <WarningCard>
                Googleビジネスプロフィールに自動反映できない住所のため、手動で反映する必要があります。
                <br />
                手動で反映する方法については、
                <ExternalLink href={GUIDE_LINKS.gmbPatchFailed}>STORECAST利用ガイド</ExternalLink>
                を確認してください。
              </WarningCard>
            )}
          </StoreItemCard>
          <StyledStoreItemCard>
            {!!latlng.latitude && latlng.longitude && (
              <>
                <StyledGoogleMap latitude={latlng.latitude} longitude={latlng.longitude} zoom={LATLNG_MAP_ZOOM_LEVEL} />
                <GoogleMapAlert>
                  STORECASTからGoogleビジネスプロフィールの緯度経度を変更することはできません。
                  {currentUser.canImportLatlng && (
                    <>
                      <br />
                      変更が必要な場合はGoogleビジネスプロフィールで地図の緯度経度を変更したのち、
                      <br />
                      以下の「Googleビジネスプロフィールから緯度経度を取り込む」を押してください。
                    </>
                  )}
                </GoogleMapAlert>
              </>
            )}
            {is_connected_gmb && currentUser.canImportLatlng && (
              <ImportGoogleMapLatLngButtonWrapper>
                <Button onClick={toggleImportGmbMapLatlngState} priority='low'>
                  Googleビジネスプロフィールから緯度経度を取り込む
                </Button>
              </ImportGoogleMapLatLngButtonWrapper>
            )}
          </StyledStoreItemCard>
          <StoreItemCard
            labelName='営業時間'
            showEditButton={showEditButton}
            onClick={() => onOpenEditModal('regularHours')}
            isRecommend
          >
            {regularHoursForDisplay.map((businessHour, index) => (
              <div key={index}>{businessHour}</div>
            ))}
          </StoreItemCard>
          <StoreItemCard
            labelName='特別営業時間'
            showEditButton={showEditButton}
            onClick={() => onOpenEditModal('specialHours')}
          >
            {specialHoursForDisplay.map((specialHour, index) => (
              <div key={index}>{specialHour}</div>
            ))}
          </StoreItemCard>
          {primaryCategory.hasMoreHours() && (
            <StoreItemCard
              labelName='その他の営業時間'
              showEditButton={showEditButton}
              onClick={() => onOpenEditModal('moreHours')}
            >
              <MoreHoursList moreHours={moreHours} moreHoursTypes={availableMoreHoursTypes} />
            </StoreItemCard>
          )}
          <StoreItemCard
            labelName='電話番号'
            showEditButton={showEditButton}
            onClick={() => onOpenEditModal('phone')}
            isRecommend
          >
            {primaryPhone}
            {additionalPhones.map((additionalPhone, index) => (
              <div key={index}>{additionalPhone}</div>
            ))}
          </StoreItemCard>
          <StoreItemCard
            labelName='ウェブサイト'
            showEditButton={showEditButton}
            onClick={() => onOpenEditModal('websiteUrl')}
            isRecommend
          >
            {websiteUrl}
          </StoreItemCard>
          {(attributeMetadatas.hasUrlAttribute() || urlAttributes.hasUrlAttribute()) && (
            <StoreItemCard
              labelName=''
              showEditButton={showEditButton}
              onClick={() => onOpenEditModal('urlAttributes')}
            >
              <UrlAttributeList
                attributes={urlAttributes}
                attributeMetadatas={attributeMetadatas}
                displayEmptyAttributeGroup={true}
              />
            </StoreItemCard>
          )}
          {attributeMetadatas.hasAttribute() && (
            <StoreItemCard
              labelName='属性'
              showEditButton={showEditButton}
              onClick={() => onOpenEditModal('attributes')}
            >
              <AttributeList
                attributes={attributes}
                attributeMetadatas={attributeMetadatas}
                displayEmptyAttributeGroup={true}
              />
            </StoreItemCard>
          )}
          <StoreItemCard
            labelName='ビジネス情報'
            showEditButton={showEditButton}
            onClick={() => onOpenEditModal('profile')}
            isRecommend
          >
            {profile.description}
          </StoreItemCard>
          {currentUser.organization?.canUseCompetitor() && !currentUser.isMemberUser && (
            <StoreItemCard labelName='競合店舗'>
              {competitors.items.size > 0}
              <CompetitorContainer>
                {competitors.items.map((item) => (
                  <Competitor key={item.id}>
                    {item.name}
                    <ExternalLink href={item.url} />
                  </Competitor>
                ))}
                {competitors.restCount > 0 && (
                  <>
                    他 <b>{competitors.restCount}</b> 個の競合店舗が設定されています。
                  </>
                )}
              </CompetitorContainer>
              競合店舗の追加・削除は<StyledLink to={`${Path.competitors.index}?si=${storeId}`}>競合店舗設定</StyledLink>
              から実施できます。
            </StoreItemCard>
          )}
        </CardListWrapper>
        {showDeleteStoreButton && (
          <DeleteWrapper>
            <NegativeButton priority='low' onClick={toggleDeleteModal}>
              <DeleteIcon name='trash alternate outline' />
              店舗を削除する
            </NegativeButton>
          </DeleteWrapper>
        )}
      </Wrapper>

      <EditStoreItemModal
        isOpen={editModalState.isOpen}
        type={editModalState.type}
        store={storeForDetail}
        storeForEdit={storeForEdit}
        onClose={onCloseEditModal}
        changeStore={(v) => dispatch(StoreDetailActions.setStoreForEdit(v))}
        updateStore={updateStore}
        categoryList={gmbCategoryList}
        attributeMetadatas={attributeMetadatas}
      />
      <ConfirmModal
        type='small'
        open={gmbModalState}
        onNegativeExecution={() => setGmbModalState(false)}
        onExecution={() => {
          setGmbModalState(false);
          dispatch(StoreDetailActions.removeGmbConnect());
        }}
        contentText='解除'
        text={`${name} ${branch ? branch : ''} のGoogleビジネスプロフィール連携を解除しますか？`}
      />
      <StoreDeleteModal
        isOpen={deleteModalState}
        onClose={toggleDeleteModal}
        onSubmit={onDelete}
        storeName={`${name}${branch ? branch : ''}`}
      />

      <GmbConnectModal />

      <GmbLatlngImportModal
        isOpen={importGmbMapLatlngState}
        onClose={toggleImportGmbMapLatlngState}
        onSubmit={importGmbMapLatlng}
      />
    </>
  );
});

const Wrapper = styled.div``;

const StoreItemsCheckWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
`;

const StoreItemsCheckGraph = styled.div`
  width: 55%;
  display: block;
`;

const StoreItemsCheckGraphTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 25px;
`;

const StoreItemsCheckGraphContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StoreItemsCheckTable = styled.div`
  width: 45%;
`;

const StoreItemNameCell = styled(TableCell)`
  &&& {
    font-weight: bold;
    max-width: 50px;
    word-break: break-word;
  }
`;

const StoreItemValueCell = styled(TableCell)``;

const GmbCardWrapper = styled.div`
  margin-bottom: 24px;
`;

const GmbConnectText = styled.p<{ is_connected_gmb: boolean }>`
  color: ${(props) => (props.is_connected_gmb ? 'inherit' : COLOR.RED)};
`;

const CardListWrapper = styled.div`
  margin-top: 24px;
`;

const StoreName = styled.div`
  display: inline-block;
`;

const StoreBranch = styled.div`
  display: inline-block;
  margin-left: 10px;
`;

const SubLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${COLOR.GRAY};
`;

const GmbRemove = styled.div`
  margin-top: 4px;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  color: ${COLOR.GREEN};
  cursor: pointer;
`;

const WarningCard = styled.div`
  font-size: 12px;
  background-color: ${COLOR.WHITE};
  color: ${COLOR.ERROR};
`;

const GmbErrorReason = styled.span`
  font-weight: bold;
  vertical-align: baseline;
  text-decoration: underline;

  :not(:last-of-type) {
    &:after {
      display: inline-block;
      content: '/';
      margin: 0 2px;
      font-weight: normal;
      text-decoration: none;
    }
  }
`;

const DeleteIcon = styled(Icon)`
  &&& {
    line-height: 1;
    height: auto;
  }
`;

const DeleteWrapper = styled.div`
  margin-top: 20px;
  color: #f00;
  font-size: 16px;
  font-weight: bold;
  ${Mixins.flex('center')}
  justify-content: center;
`;

const NegativeButton = styled(Button)`
  &&& {
    display: flex;
    align-items: center;
    color: ${COLOR.RED};
  }
`;

const StyledGoogleMap = styled(GoogleMap)`
  &&& {
    width: 100%;
    height: 200px;
  }
`;

const StyledStoreItemCard = styled.div`
  &&& {
    padding: 0 18px 18px;
    background-color: ${COLOR.WHITE};
  }
`;

const ImportGoogleMapLatLngButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const GoogleMapAlert = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  margin: 8px 0;
`;

const CompetitorContainer = styled.div`
  margin-bottom: 16px;
  b {
    font-weight: bold;
  }
`;

const Competitor = styled.div``;

const StyledLink = styled(Link)`
  color: ${COLOR.GREEN};
`;
