import { List } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { CategoryApi, GmbAttributeListApi, GmbLocationUpdatedApi } from 'ApiClient/GmbApi';
import { StoreApi } from 'ApiClient/StoreApi';
import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbLocation } from 'models/Domain/GmbLocation/GmbLocation';
import { GmbLocationCategory } from 'models/Domain/GmbLocation/GmbLocationCategories';
import { MoreHoursType } from 'models/Domain/GmbLocation/MoreHours';
import { ServiceType } from 'models/Domain/GmbLocation/Service';
import { GmbLocationDiff } from 'models/Domain/GmbLocationDiffs';
import { GmbLocationUpdates } from 'models/Domain/GmbLocationUpdates';
import { Store, Stores } from 'models/Domain/Store';
import { JSObject, assertNever } from 'types/Common';

import { AppActions } from '../app/actions';

import { GmbLocationUpdatesActions } from './actions';
import { GmbLocationUpdatesSelectors } from './selectors';
import { UpdateType } from './types';

export default function* saga() {
  yield takeLatest(GmbLocationUpdatesActions.initialize, initialize);
  yield takeLatest(GmbLocationUpdatesActions.fetchGmbLocationUpdates, fetchGmbLocationUpdates);
  yield takeLatest(GmbLocationUpdatesActions.updateStore, updateStore);
  yield takeLatest(GmbLocationUpdatesActions.updateStoreAttributes, updateStoreAttributes);
  yield takeLatest(GmbLocationUpdatesActions.updateStoreMoreHours, updateStoreMoreHours);
  yield takeLatest(GmbLocationUpdatesActions.bulkUpdateStores, bulkUpdateStores);
  yield takeLatest(GmbLocationUpdatesActions.fetchAttributeMetadatas, fetchAttributeMetadatas);
  yield takeLatest(GmbLocationUpdatesActions.fetchCategoryDatas, fetchCategoryDatas);
}

/**
 * 初期化処理
 * @param action
 */
function* initialize(action: ReturnType<typeof GmbLocationUpdatesActions.initialize>) {
  yield put(GmbLocationUpdatesActions.fetchGmbLocationUpdates());
  yield put(AppActions.getGmbLocationDiffs()); // サイドバーのGBPとの差分のバッジを更新する
}

/**
 * Googleビジネスプロフィールとの差分情報を取得する
 * @param action
 * @returns
 */
function* fetchGmbLocationUpdates(action: ReturnType<typeof GmbLocationUpdatesActions.fetchGmbLocationUpdates>) {
  yield put(AppActions.setLoading(true));

  const response: YieldReturn<typeof GmbLocationUpdatedApi.get> = yield GmbLocationUpdatedApi.get();

  if (!response.isSuccess) {
    console.log(response.error);
    toast({
      type: 'error',
      title: 'Googleビジネスプロフィールとの差分情報の取得に失敗しました',
      time: 10000,
    });
    yield put(AppActions.setLoading(false));
    return;
  }

  const locationUpdates = GmbLocationUpdates.fromJSON(response.data as JSObject[]);
  yield put(GmbLocationUpdatesActions.fetchAttributeMetadatas(locationUpdates));
  yield put(GmbLocationUpdatesActions.fetchCategoryDatas(locationUpdates));
  yield put(GmbLocationUpdatesActions.setGmbLocationUpdates(locationUpdates));
  yield put(GmbLocationUpdatesActions.setInitialized());

  yield put(AppActions.setLoading(false));
}

/**
 * １項目の差分情報を更新する
 * @param action
 * @returns
 */
function* updateStore(action: ReturnType<typeof GmbLocationUpdatesActions.updateStore>) {
  try {
    yield put(AppActions.setLoading(true));

    const { target, type } = action.payload;
    const storeId = target.store_id;

    const stores: Stores = yield select((state) => state.store.stores);
    const { locationUpdates } = (yield select(GmbLocationUpdatesSelectors.selectState)) as ReturnType<
      typeof GmbLocationUpdatesSelectors.selectState
    >;

    const targetStore = stores.findStore(storeId);
    const location = locationUpdates.getLocation(storeId, type);

    if (!targetStore || !location) {
      return;
    }

    const response: YieldReturn<typeof updateStoreItem> = yield updateStoreItem(target, type, location, targetStore);
    if (!response.isSuccess) {
      toast({
        type: 'error',
        title: '店舗情報の更新に失敗しました',
        time: 10000,
      });
      return;
    }
    toast({
      type: 'success',
      title: '店舗情報を更新しました',
    });
    yield put(GmbLocationUpdatesActions.setUpdatedItems({ diffs: List([target]), type }));
    yield put(AppActions.getGmbLocationDiffs()); // サイドバーのGBPとの差分のバッジを更新する
  } finally {
    yield put(AppActions.setLoading(false));
  }
}

function* updateStoreAttributes(action: ReturnType<typeof GmbLocationUpdatesActions.updateStoreAttributes>) {
  try {
    yield put(AppActions.setLoading(true));
    const { target, attributes, urlAttributes } = action.payload;

    const storeId = target.store_id;

    const mergedAttributes = [...attributes.updateParams, ...urlAttributes.updateParams];

    const response: YieldReturn<typeof updateStoreItem> = yield updateStoreAttributesItem(storeId, {
      attributes: mergedAttributes,
    });

    if (!response.isSuccess) {
      console.log(response.error);
      toast({
        type: 'error',
        title: '店舗の属性情報の更新に失敗しました',
        time: 10000,
      });
      return;
    }
    toast({
      type: 'success',
      title: '店舗の属性情報を更新しました',
    });
    yield put(GmbLocationUpdatesActions.setUpdatedItems({ diffs: List([target]), type: 'CUSTOM' }));
  } finally {
    yield put(AppActions.setLoading(false));
  }
}

function* updateStoreMoreHours(action: ReturnType<typeof GmbLocationUpdatesActions.updateStoreMoreHours>) {
  try {
    yield put(AppActions.setLoading(true));
    const { target, moreHours } = action.payload;

    const storeId = target.store_id;

    const response: YieldReturn<typeof updateStoreItem> = yield updateStoreMoreHoursItem(storeId, {
      moreHours: moreHours.updateParams,
    });

    if (!response.isSuccess) {
      console.log(response.error);
      toast({
        type: 'error',
        title: 'その他の営業時間の更新に失敗しました',
        time: 10000,
      });
      return;
    }
    toast({
      type: 'success',
      title: 'その他の営業時間を更新しました',
    });
    yield put(GmbLocationUpdatesActions.setUpdatedItems({ diffs: List([target]), type: 'CUSTOM' }));
  } finally {
    yield put(AppActions.setLoading(false));
  }
}

/**
 * 複数項目の差分情報を更新する
 * @param action
 * @returns
 */
function* bulkUpdateStores(action: ReturnType<typeof GmbLocationUpdatesActions.bulkUpdateStores>) {
  try {
    yield put(AppActions.setLoading(true));

    const { type } = action.payload;

    const stores: Stores = yield select((state) => state.store.stores);
    const { locationUpdates, filteredCheckedItems: checkedItems } = (yield select(
      GmbLocationUpdatesSelectors.selectState,
    )) as ReturnType<typeof GmbLocationUpdatesSelectors.selectState>;

    // 成功数と失敗数をカウントする
    const successItems: GmbLocationDiff[] = [];
    const failedItems: GmbLocationDiff[] = [];

    // 一括で更新するAPIは一部の項目しか対応していないので、項目をシーケンシャルに処理する
    for (const item of checkedItems.toArray()) {
      const storeId = item.store_id;

      const targetStore = stores.findStore(storeId);
      const location = locationUpdates.getLocation(storeId, type);

      if (!targetStore || !location) {
        continue;
      }

      // STORECASTの情報を一括反映する場合、差分がaddressで、店舗の住所のGBPへの反映状況が失敗の場合、スキップ
      if (type === 'STORECAST' && item.key === 'address' && !targetStore.location_state.canApplyAddressToGmb) {
        continue;
      }
      // STORECASTの情報を一括反映する場合、緯度経度はAPI経由で更新できないため、スキップ
      if (type === 'STORECAST' && item.key === 'latlng') {
        continue;
      }

      const response: YieldReturn<typeof updateStoreItem> = yield updateStoreItem(item, type, location, targetStore);
      if (response.isSuccess) {
        successItems.push(item);
      } else {
        failedItems.push(item);
      }
    }

    // 更新済みにマーク
    yield put(GmbLocationUpdatesActions.setUpdatedItems({ diffs: List(successItems), type }));

    if (successItems.length > 0) {
      toast({
        type: 'success',
        title: `${successItems.length}項目を更新しました`,
      });
    }

    // 更新に失敗したものがある場合、メッセージを表示する
    if (failedItems.length > 0) {
      toast({
        type: 'error',
        title: `${failedItems.length}項目の更新に失敗しました`,
        time: 10000,
      });
    }
  } finally {
    yield put(AppActions.setLoading(false));
  }
}

/**
 * 差分更新を実施する
 * @param target 更新対象の項目
 * @param type STORECASTの情報と、Googleからの提案のどちらを採用するか
 * @param location データ取得元のロケーション情報(事前にtypeによって振り分けておくこと)
 * @param store 対象項目のStoreデータ(一部の情報の更新で利用する)
 * @returns
 */
function updateStoreItem(target: GmbLocationDiff, type: UpdateType, location: GmbLocation, store: Store) {
  const storeId = target.store_id;

  switch (target.key) {
    case 'locationName': {
      // 店舗名はSTORECAST側は'name', 'branch'による管理をしているが、
      // GBP側は'locationName'のみで管理していて支店名の別管理は行っていないため、
      // 選択肢によって反映元のデータを切り替える
      const params =
        type === 'STORECAST'
          ? store.updateNameBranchParams()
          : {
              name: location.locationName,
              branch: '',
            };
      return StoreApi.patchNameBranch(storeId, params);
    }
    case 'storeCode': {
      const params = { code: location.storeCode };
      return StoreApi.patchCode(storeId, params);
    }
    case 'websiteUrl': {
      const params = location.updateWebsiteUrlParams();
      return StoreApi.patchWebsiteUrl(storeId, params);
    }
    case 'phoneNumbers': {
      const params = location.updatePhoneParams();
      return StoreApi.patchPhone(storeId, params);
    }
    case 'address': {
      const params = location.updateAddressParams();
      return StoreApi.patchAddress(storeId, params);
    }
    case 'latlng': {
      const params = location.updateGmbLatlngParams();
      return StoreApi.patchGmbMapLatlng(storeId, params);
    }
    case 'regularHours': {
      const params = location.updateRegularHoursParams();
      return StoreApi.patchRegularHours(storeId, params);
    }
    case 'specialHours': {
      const params = location.updateSpecialHoursParams();
      return StoreApi.patchSpecialHours(storeId, params);
    }
    case 'moreHours': {
      const params = location.updateMoreHoursParams();
      return StoreApi.patchMoreHours(storeId, params);
    }

    case 'categories': {
      const params = location.updateCategoriesParams();
      return StoreApi.patchGmbCategories(storeId, params);
    }
    case 'attributes': {
      const params = location.updateAttributesParams();
      return StoreApi.patchAttributes(storeId, params);
    }
    case 'openInfo': {
      const params = location.updateOpenInfoParams();
      return StoreApi.patchGmbOpenInfo(storeId, params);
    }
    case 'profile': {
      const params = location.updateProfileParams();
      return StoreApi.patchProfile(storeId, params);
    }
    case 'openingDate': {
      const params = location.updateOpeningDateParams();
      return StoreApi.patchGmbOpeningDate(storeId, params);
    }
    case 'serviceItems': {
      // 実装上ここにくることはないけど、一応エラーを出すようにしておく
      throw new Error('サービスの更新はサービスページでの更新のみ対応です');
    }
    default: {
      return assertNever(target.key);
    }
  }
}

/**
 * 属性の差分更新を実施する
 * @param storeId 更新対象の店舗ID
 * @param params 属性の更新パラメータ
 * @returns
 */
function updateStoreAttributesItem(storeId: number, params: { attributes: any[] }) {
  return StoreApi.patchAttributes(storeId, params);
}

function updateStoreMoreHoursItem(storeId: number, params: { moreHours: any[] }) {
  return StoreApi.patchMoreHours(storeId, params);
}

/**
 * gmbLocationUpdates含まれる全てのカテゴリーに対する属性メタデータを取得し、ストアにセットする
 */
function* fetchAttributeMetadatas(action: ReturnType<typeof GmbLocationUpdatesActions.fetchAttributeMetadatas>) {
  yield put(AppActions.setLoading(true));
  const categoryIds = action.payload.primaryCategoryIds;

  // yield allで一括でAPI実行する
  const requests = categoryIds.reduce<{ [key: string]: any }>((current, categoryId) => {
    current[categoryId] = call(GmbAttributeListApi.get, categoryId);
    return current;
  }, {});
  const responses: { [key: string]: any } = yield all(requests);

  // 1つでもエラーがある場合はメッセージ表示
  const hasError = !!Object.values(responses).find((response) => !response.isSuccess);
  if (hasError) {
    console.error('fetch attributeMetadatas error', responses);
    toast({
      type: 'error',
      title: '属性メタデータの取得に失敗しました',
      time: 10000,
    });
    return;
  }
  // カテゴリーIDごとの属性メタデータに変換してストアにセットする
  const attributeMetadatas = Object.entries(responses).reduce<{ [key: string]: GmbAttributeMetadatas }>(
    (current, [categoryId, response]) => {
      current[categoryId] = new GmbAttributeMetadatas(response.data);
      return current;
    },
    {},
  );
  yield put(GmbLocationUpdatesActions.setAttributeMetadatas({ attributeMetadatas }));
  yield put(AppActions.setLoading(false));
}

/**
 * gmbLocationUpdates含まれる全てのカテゴリーに対する利用可能なその他の営業時間を取得し、ストアにセットする
 */
function* fetchCategoryDatas(action: ReturnType<typeof GmbLocationUpdatesActions.fetchCategoryDatas>) {
  yield put(AppActions.setLoading(true));
  const allCategoryIds = action.payload.allCategoryIds;
  // yield allで一括でAPI実行する
  const requests = allCategoryIds.reduce<{ [key: string]: any }>((current, categoryId) => {
    current[categoryId] = call(CategoryApi.get, categoryId);
    return current;
  }, {});
  const responses: { [key: string]: any } = yield all(requests);

  // 1つでもエラーがある場合はメッセージ表示
  const hasError = !!Object.values(responses).find((response) => !response.isSuccess);
  if (hasError) {
    console.error('fetch moreHoursTypes error', responses);
    toast({
      type: 'error',
      title: 'カテゴリデータの取得に失敗しました',
      time: 10000,
    });
    return;
  }
  // カテゴリーIDごとのその他の営業時間に変換してストアにセットする
  const moreHoursTypes = Object.entries(responses).reduce<{ [key: string]: List<MoreHoursType> }>(
    (current, [categoryId, response]) => {
      const category = new GmbLocationCategory(response.data);
      current[categoryId] = category.moreHoursTypes ?? List<MoreHoursType>();
      return current;
    },
    {},
  );
  const serviceTypes = Object.entries(responses).reduce<{ [key: string]: List<ServiceType> }>(
    (current, [categoryId, response]) => {
      const category = new GmbLocationCategory(response.data);
      current[categoryId] = category.serviceTypes ?? List<ServiceType>();
      return current;
    },
    {},
  );

  yield put(GmbLocationUpdatesActions.setCategoryDatas({ moreHoursTypes, serviceTypes }));
  yield put(AppActions.setLoading(false));
}
